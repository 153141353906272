export enum LOGIN_TYPE {
  DEFAULT = 'default',
  GUEST = 'guest',
  KAKAO = 'kakao',
  NAVER = 'naver',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

export const FREQUENCY_TYPE: any = {
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3,
};

export enum CATEGORY_TYPE {
  TREND_INDEX = 1,
  STOCK = 2,
}

export enum PRODUCT_TYPE {
  TICKET = 1,
  SUBSCRIPTION = 2,
}

export enum EVENT_NAME {
  CONNECTION = 'connection',
  DISCONNECT = 'disconnect',
  CONNECTED = 'connected',
  SEND_MESSAGE = 'sendMessage',

  PAYMENT_MESSAGE = 'paymentMessage',

  EXCEPTION = 'exception',
  ERROR = 'error',
}

export enum PAYMENT_PROCESS_STATUS {
  INITIATED = 1, // Initial
  PAYMENT_SUCCESS = 10, // Payment success
  CONFIRM_USER_CARD_SUCCESS = 20, // Confirm user card success
  PAYMENT_VERIFIED = 21, // Payment verified
  CONFIRM_USER_CARD_ERROR = 25, // Confirm user card error
  REQUEST_BILL_ERROR = 30, // Request bill success
  PAY_CANCELED = 40, // User has canceled pay.
  PAYMENT_ERROR = 50, // Payment error
}

export enum WS_EVENT_USER_PATTERNS {
  PAYMENT_NOTIFY = 'payment:notify',
  TOTAL_TICKET_AMOUNT_NOTIFY = 'total-ticket-amount:notify',
}

export enum TYPE_MESSAGES {
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
  PAYMENT_CANCEL = 'PAYMENT_CANCEL',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
}
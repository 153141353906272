import React, { useEffect, useState } from "react";
import Row from "./Row";
import { Col, Container, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Body = ({ data = [], frequencyType, categoryType, isShowVote = false, handleVote, handleShowMore, widthChart }: any) => {

    const handleActionVote = (row: any) => {
        handleVote && handleVote(row);
    }
    const showMore = (keywords: string[] = []) => {
        handleShowMore && handleShowMore(keywords);
    }
    return (
        <>
            <tbody>
                {data?.map((item: any, ind: number) => (
                    <Row data={item} widthChart={widthChart} frequencyType={frequencyType} categoryType={categoryType} key={ind} isShowVote={isShowVote} handleVote={handleActionVote} handleShowMore={showMore} />
                ))}
            </tbody>
        </>
    );
};

export default React.memo(Body);

import React, { useReducer, useContext, useEffect, useState } from "react";
/// Link
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { MenuList } from './Menu';
import { styled } from '@mui/system';
import { MenuListBottom } from './Menu';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';


import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import SidebarExtraContent from "./SidebarExtraContent";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { allCategoryMenu as onGetAllCategoryMenu } from "store/thunks";
import { toggleMenuLayout } from "store/layouts/thunk";
import { CATEGORY_TYPE } from "helpers/constans";
import ButtonDanger from "ui/components/buttons/ButtonDanger";

// import LogoutPage from './Logout';
/// Image
// import profile from "../../../assets/images/profile/pic1.jpg";

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
}

const ICON_CATEGORY = {
  '연예인': <svg width="24" height="24" className="icon-item-menu-stroke" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.7144 8.99989C11.7144 10.5113 10.6592 11.5854 9.35753 11.5854C8.05585 11.5854 7.00063 10.5113 7.00063 8.99989C7.00063 7.48848 8.05585 6.58535 9.35753 6.58535C10.6592 6.58535 11.7144 7.48848 11.7144 8.99989Z" stroke="#6A6970" stroke-width="2" stroke-linejoin="round" />
    <path d="M15.5 17.6851C15.5 19.2397 13.6818 20.4999 9.5 20.4999C5.31818 20.4999 3.5 19.2397 3.5 17.6851C3.5 16.1305 6.18629 14.8702 9.5 14.8702C12.8137 14.8702 15.5 16.1305 15.5 17.6851Z" stroke="#6A6970" stroke-width="2" stroke-linejoin="round" />
    <path d="M17 4L17.8499 5.83016L19.8532 6.07295L18.3752 7.44684L18.7634 9.42705L17 8.446L15.2366 9.42705L15.6248 7.44684L14.1468 6.07295L16.1501 5.83016L17 4Z" stroke="#6A6970" stroke-width="2" stroke-linejoin="round" />
  </svg>,
  '스포츠': <svg width="24" height="24" className="icon-item-menu-stroke-rect" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_500_411)">
      <circle cx="12" cy="12" r="8" stroke="#6A6970" stroke-width="2" />
      <path d="M18.572 6.42127C17.8483 7.20326 16.8869 7.84488 15.7672 8.29313C14.6475 8.74138 13.4017 8.98339 12.1326 8.99918C10.8636 9.01496 9.60778 8.80406 8.4689 8.3839C7.33002 7.96374 6.34077 7.34638 5.58288 6.58282" stroke="#6A6970" stroke-width="2" />
      <path d="M18.572 17.5787C17.8483 16.7967 16.8869 16.1551 15.7672 15.7069C14.6475 15.2586 13.4017 15.0166 12.1326 15.0008C10.8636 14.985 9.60778 15.1959 8.4689 15.6161C7.33002 16.0363 6.34077 16.6536 5.58288 17.4172" stroke="#6A6970" stroke-width="2" />
      <path d="M12 4V20" stroke="#6A6970" stroke-width="2" />
      <path d="M20 12H4" stroke="#6A6970" stroke-width="2" />
    </g>
    <defs>
      <clipPath id="clip0_500_411">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  '상장사': <svg width="24" height="22" className="icon-item-menu-stroke-rect" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 3V6" stroke="#6A6970" stroke-width="2" stroke-linecap="round" />
    <path d="M8 14V17" stroke="#6A6970" stroke-width="2" stroke-linecap="round" />
    <rect x="6" y="6" width="4" height="8" rx="0.8" stroke="#6A6970" stroke-width="2" />
    <path d="M16 7V12" stroke="#6A6970" stroke-width="2" stroke-linecap="round" />
    <path d="M16 17V20" stroke="#6A6970" stroke-width="2" stroke-linecap="round" />
    <rect x="14" y="12" width="4" height="5" rx="0.8" stroke="#6A6970" stroke-width="2" />
  </svg>,
  '대한민국': <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="icon-item-menu-stroke-rect" viewBox="0 0 24 24" fill="none">
    <path d="M3 21V17M9 21V13M15 21V15M21 21V11M8.43934 5.56066C8.71079 5.83211 9.08579 6 9.5 6C9.91421 6 10.2892 5.83211 10.5607 5.56066M8.43934 5.56066C8.16789 5.28921 8 4.91421 8 4.5C8 3.67157 8.67157 3 9.5 3C10.3284 3 11 3.67157 11 4.5C11 4.91421 10.8321 5.28921 10.5607 5.56066M8.43934 5.56066L5.56066 8.43934M5.56066 8.43934C5.28921 8.16789 4.91421 8 4.5 8C3.67157 8 3 8.67157 3 9.5C3 10.3284 3.67157 11 4.5 11C5.32843 11 6 10.3284 6 9.5C6 9.08579 5.83211 8.71079 5.56066 8.43934ZM10.5607 5.56066L13.4393 8.43934M13.4393 8.43934C13.1679 8.71079 13 9.08579 13 9.5C13 10.3284 13.6716 11 14.5 11C15.3284 11 16 10.3284 16 9.5C16 9.08579 15.8321 8.71079 15.5607 8.43934M13.4393 8.43934C13.7108 8.16789 14.0858 8 14.5 8C14.9142 8 15.2892 8.16789 15.5607 8.43934M15.5607 8.43934L18.4393 5.56066M18.4393 5.56066C18.7108 5.83211 19.0858 6 19.5 6C20.3284 6 21 5.32843 21 4.5C21 3.67157 20.3284 3 19.5 3C18.6716 3 18 3.67157 18 4.5C18 4.91421 18.1679 5.28921 18.4393 5.56066Z" stroke="#6A6970" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>,

}
const CustomListItemButton = styled(ListItemButton)(({ theme, isActive, isActiveText, isActiveStroke = false, isActiveFill = false }) => ({
  borderRadius: '10px',
  marginBottom: '10px',
  color: 'rgb(106, 105, 112)',
  fontSize: '1rem',
  ...(isActive ? {
    ...(isActiveText ? {} : {
      backgroundColor: 'var(--danger)'
    }),
    color: isActiveText ? 'var(--danger)' : '#fff',
    ...(!isActiveText ? { boxShadow: '0 6px 10px 0 rgba(2, 2, 32, 0.2)', } : {}),
  } : {}),
  '& .text-item-menu span': {
    ...(isActive && isActiveText ? { fontWeight: '700' } : {})
  },
  '& svg.icon-item-menu path': {
    ...(isActive ? { ...(isActiveFill ? { fill: '#F6B40C' } : {}), ...(isActiveStroke ? { stroke: '#F6B40C' } : {}) } : {})
  },
  '&:hover': {
    ...(!isActiveText && isActive ? { backgroundColor: 'var(--danger)', color: '#fff' } : {}),
  },
}));


const SideBar = () => {
  let Latest = new Date();
  const {
    windowWidth,
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    menuToggle,
    ChangeIconSidebar,
  } = useContext(ThemeContext);

  const isDark = document.body.getAttribute('data-theme-version') === 'dark';

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  const { t, i18n } = useTranslation();
  const ref = React.useRef();

  const [searchParams] = useSearchParams();
  const category_id = searchParams.get('category_id');

  const [open, setOpen] = React.useState(`/${path}`);
  let navigate = useNavigate();

  const handleClick = (open_path) => {
    setOpen((_prev) => (_prev === open_path ? '' : open_path));
    if (!!open_path && open !== open_path) {
      if (open_path === '/people') {
        navigate(menuPeople[0]?.path);
      }
      if (open_path === '/industry') {
        navigate(menuIndustry[0]?.path);
      }
    }
  };

  const dispatch = useDispatch();

  const [state, setState] = useReducer(reducer, initialState);
  const { sideMenu } = useSelector(state => state.Layout);

  const [menuList, setMenuList] = useState(MenuList || []);

  const [menuPeople, setMenuPeople] = useState([]);

  const [menuIndustry, setMenuIndustry] = useState([]);

  const selectLayoutState = (state) => state.Master;

  const MasterProperties = createSelector(
    selectLayoutState,
    (state) => ({
      menu: state.menu,
      isMenuSuccess: state.isMenuSuccess,
      isMenuLoading: state.isMenuLoading,
      error: state.error,
    })
  );
  const { menu, isMenuLoading } = useSelector(MasterProperties);

  const [hideOnScroll, setHideOnScroll] = useState(true);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll]
  )

  const handleMenuActive = status => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  }

  const handleCloseMenu = () => {
    if (windowWidth < 1024) {
      dispatch(toggleMenuLayout());
    }
  }

  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status })
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" })
    }
  }


  useEffect(() => {
    // menuList.forEach((data) => {
    //   if (!data?.content && (`/${path}`) === (data.path || '')) {
    //     setState({ active: data.title, activeSubmenu: "" });
    //   }
    //   data.content?.forEach((item) => {
    //     if (`/${path}` === item.path) {
    //       setState({ active: data.title })
    //     }
    //     item.content?.forEach(ele => {
    //       if (`/${path}` === ele.path) {
    //         setState({ activeSubmenu: item.title, active: data.title })
    //       }
    //     })
    //   });
    // })
    if (['/', '/service-introduction']?.includes(`/${path}`) && !!open) {
      setOpen((_prev) => '');
    } if (!['/', '/service-introduction']?.includes(`/${path}` && `/${path}` !== open)) {
      setOpen((_prev) => `/${path}`);
    }
  }, [path, open]);

  useEffect(() => {
    setMenuList((_prev) => {
      const cate_people = (menu || [])?.filter((item) => String(item?.type) === String(CATEGORY_TYPE.TREND_INDEX));
      const cate_industry = (menu || [])?.filter((item) => String(item?.type) === String(CATEGORY_TYPE.STOCK));
      const m = ([...cate_people, ...cate_industry])?.map((item) => ({
        title: String(item?.type) === String(CATEGORY_TYPE.STOCK) ? t('Corporation Rankify') : `${item?.name || ''} ${t('Rankify_KR')}`,
        classsChange: 'mm-collapse',
        iconStyle: ICON_CATEGORY?.[item?.name] || <i className="material-symbols-outlined">monitoring</i>,
        path: `/category/${item?.id}`,
        to: `/category/${item?.id}?is_clear=1`
      }));
      setMenuPeople(() => {
        return cate_people?.map((item) => ({
          title: `${item?.name || ''} ${t('Rankify_KR')}`,
          path_parent: '/people',
          path: `/people?category_id=${item?.id}`,
          id: item?.id,
        }));
      });
      setMenuIndustry(() => {
        return cate_industry?.map((item) => ({
          title: t('Corporation Rankify'),
          path_parent: '/industry',
          path: `/industry?category_id=${item?.id}`,
          id: item?.id
        }));
      });
      return MenuList.concat(m || []);
    });
  }, [JSON.stringify(menu), i18n?.language]);

  useEffect(() => {
    dispatch(onGetAllCategoryMenu({ depth: 1 }));
  }, []);

  React.useEffect(() => {
    const handleClick = (event) => {
      event.stopPropagation();
      if (ref.current && !ref.current.contains(event.target) && window.innerWidth < 800) {
        if (sideMenu) {
          handleCloseMenu();
        }
      }
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref, sideMenu]);



  return (
    <div
      ref={ref}
      onMouseEnter={() => ChangeIconSidebar(true)}
      onMouseLeave={() => ChangeIconSidebar(false)}
      className={`dlabnav ${path === "" || path === "dashboard" || path === "index-2" ? 'follow-info' : ''} ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? hideOnScroll > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >

      {/* <SidebarExtraContent /> */}
      {/* <span className="main-menu">Main Menu</span> */}
      <div className="menu-scroll">
        <div className="dlabnav-scroll dlabnav-scroll-custom d-flex flex-column justify-content-between px-3">
          <ul className="metismenu flex-grow-1 pt-3 overflow-y-auto" id="menu">
            <List
              sx={{ width: '100%', bgcolor: 'background.paper' }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <CustomListItemButton sx={{ pl: 2 }} isActive={`/${path}` === '/'} isActiveText={false} isActiveFill={true} onClick={() => { navigate('/'); handleCloseMenu(); handleClick(''); }}>
                <ListItemIcon style={{ minWidth: '34px' }}>
                  <svg width="24" height="24" className="icon-item-menu" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13 21C13 21.553 13.447 22 14 22H17C18.061 22 19.078 21.579 19.828 20.829C20.578 20.079 21 19.061 21 18C21 17.426 21 17 21 17C21 16.448 20.552 16 20 16H14C13.447 16 13 16.448 13 17V21ZM10 22C10.552 22 11 21.553 11 21V11C11 10.448 10.552 10 10 10H4C3.447 10 3 10.448 3 11V18C3 19.061 3.421 20.079 4.171 20.829C4.921 21.579 5.939 22 7 22H10ZM19 18H15V20H17C17.53 20 18.039 19.79 18.414 19.415C18.789 19.04 19 18.531 19 18ZM9 20V12H5V18C5 18.531 5.21 19.04 5.586 19.415C5.961 19.79 6.469 20 7 20H9ZM14 2C13.447 2 13 2.448 13 3V13C13 13.553 13.447 14 14 14H20C20.552 14 21 13.553 21 13V6C21 4.94 20.578 3.922 19.828 3.172C19.078 2.422 18.061 2 17 2H14ZM15 4V12H19V6C19 5.47 18.789 4.961 18.414 4.586C18.039 4.211 17.53 4 17 4H15ZM11 3C11 2.448 10.552 2 10 2H7C5.939 2 4.921 2.422 4.171 3.172C3.421 3.922 3 4.94 3 6V7C3 7.553 3.447 8 4 8H10C10.552 8 11 7.553 11 7V3ZM9 4H7C6.469 4 5.961 4.211 5.586 4.586C5.21 4.961 5 5.47 5 6H9V4Z" fill="#6A6970"></path></svg>
                </ListItemIcon>
                <ListItemText primary={t('Dashboard')} />
              </CustomListItemButton>

              {/* <CustomListItemButton sx={{ pl: 2 }} isActive={`/${path}` === '/people'} isActiveText={false} isActiveStroke={true} onClick={() => { navigate('/people'); handleCloseMenu(); }}>
                <ListItemIcon style={{ minWidth: '34px' }}>
                  <svg width="24" height="24" className="icon-item-menu-stroke" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.7144 8.99989C11.7144 10.5113 10.6592 11.5854 9.35753 11.5854C8.05585 11.5854 7.00063 10.5113 7.00063 8.99989C7.00063 7.48848 8.05585 6.58535 9.35753 6.58535C10.6592 6.58535 11.7144 7.48848 11.7144 8.99989Z" stroke="#6A6970" stroke-width="2" stroke-linejoin="round"></path><path d="M15.5 17.6851C15.5 19.2397 13.6818 20.4999 9.5 20.4999C5.31818 20.4999 3.5 19.2397 3.5 17.6851C3.5 16.1305 6.18629 14.8702 9.5 14.8702C12.8137 14.8702 15.5 16.1305 15.5 17.6851Z" stroke="#6A6970" stroke-width="2" stroke-linejoin="round"></path><path d="M17 4L17.8499 5.83016L19.8532 6.07295L18.3752 7.44684L18.7634 9.42705L17 8.446L15.2366 9.42705L15.6248 7.44684L14.1468 6.07295L16.1501 5.83016L17 4Z" stroke="#6A6970" stroke-width="2" stroke-linejoin="round"></path></svg>
                </ListItemIcon>
                <ListItemText primary={t('People')} />
              </CustomListItemButton>

              <CustomListItemButton sx={{ pl: 2 }} isActive={`/${path}` === '/industry'} isActiveText={false} isActiveStroke={true} onClick={() => { navigate('/industry'); handleCloseMenu(); }}>
                <ListItemIcon style={{ minWidth: '34px' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="icon-item-menu-stroke-rect" viewBox="0 0 24 24" fill="none"><path d="M3 21V17M9 21V13M15 21V15M21 21V11M8.43934 5.56066C8.71079 5.83211 9.08579 6 9.5 6C9.91421 6 10.2892 5.83211 10.5607 5.56066M8.43934 5.56066C8.16789 5.28921 8 4.91421 8 4.5C8 3.67157 8.67157 3 9.5 3C10.3284 3 11 3.67157 11 4.5C11 4.91421 10.8321 5.28921 10.5607 5.56066M8.43934 5.56066L5.56066 8.43934M5.56066 8.43934C5.28921 8.16789 4.91421 8 4.5 8C3.67157 8 3 8.67157 3 9.5C3 10.3284 3.67157 11 4.5 11C5.32843 11 6 10.3284 6 9.5C6 9.08579 5.83211 8.71079 5.56066 8.43934ZM10.5607 5.56066L13.4393 8.43934M13.4393 8.43934C13.1679 8.71079 13 9.08579 13 9.5C13 10.3284 13.6716 11 14.5 11C15.3284 11 16 10.3284 16 9.5C16 9.08579 15.8321 8.71079 15.5607 8.43934M13.4393 8.43934C13.7108 8.16789 14.0858 8 14.5 8C14.9142 8 15.2892 8.16789 15.5607 8.43934M15.5607 8.43934L18.4393 5.56066M18.4393 5.56066C18.7108 5.83211 19.0858 6 19.5 6C20.3284 6 21 5.32843 21 4.5C21 3.67157 20.3284 3 19.5 3C18.6716 3 18 3.67157 18 4.5C18 4.91421 18.1679 5.28921 18.4393 5.56066Z" stroke="#6A6970" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                </ListItemIcon>
                <ListItemText primary={t('Industry')} />
              </CustomListItemButton> */}

              <CustomListItemButton onClick={() => handleClick('/people')} sx={{ pl: 2 }} isActiveStroke={true} isActive={['/people']?.includes(`/${path}`) || open === '/people'} isActiveText={false}>
                <ListItemIcon style={{ minWidth: '34px' }}>
                  <svg width="24" height="24" className="icon-item-menu" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.7144 8.99989C11.7144 10.5113 10.6592 11.5854 9.35753 11.5854C8.05585 11.5854 7.00063 10.5113 7.00063 8.99989C7.00063 7.48848 8.05585 6.58535 9.35753 6.58535C10.6592 6.58535 11.7144 7.48848 11.7144 8.99989Z" stroke="#6A6970" stroke-width="2" stroke-linejoin="round"></path><path d="M15.5 17.6851C15.5 19.2397 13.6818 20.4999 9.5 20.4999C5.31818 20.4999 3.5 19.2397 3.5 17.6851C3.5 16.1305 6.18629 14.8702 9.5 14.8702C12.8137 14.8702 15.5 16.1305 15.5 17.6851Z" stroke="#6A6970" stroke-width="2" stroke-linejoin="round"></path><path d="M17 4L17.8499 5.83016L19.8532 6.07295L18.3752 7.44684L18.7634 9.42705L17 8.446L15.2366 9.42705L15.6248 7.44684L14.1468 6.07295L16.1501 5.83016L17 4Z" stroke="#6A6970" stroke-width="2" stroke-linejoin="round"></path></svg>
                </ListItemIcon>
                <ListItemText primary={t('People')} />
                {open === '/people' ? <ExpandLess /> : <ExpandMore />}
              </CustomListItemButton>
              <Collapse in={open === '/people'} timeout="auto" unmountOnExit>
                <List component="div" disablePadding >
                  {menuPeople?.map((m) => (
                    <CustomListItemButton key={m?.title} sx={{ pl: 4 }} isActive={(`/${path}` === `${m?.path_parent}`) && (String(category_id) === String(m?.id))} isActiveText={true} onClick={() => { navigate(m?.path); handleCloseMenu(); }}>
                      <ListItemIcon style={{ minWidth: '20px' }}>
                      </ListItemIcon>
                      <ListItemText className="text-item-menu" primary={m?.title || ''} />
                    </CustomListItemButton>
                  ))}
                </List>
              </Collapse>

              <CustomListItemButton onClick={() => handleClick('/industry')} sx={{ pl: 2 }} isActiveStroke={true} isActive={['/industry']?.includes(`/${path}`) || open === '/industry'} isActiveText={false}>
                <ListItemIcon style={{ minWidth: '34px' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="icon-item-menu" viewBox="0 0 24 24" fill="none"><path d="M3 21V17M9 21V13M15 21V15M21 21V11M8.43934 5.56066C8.71079 5.83211 9.08579 6 9.5 6C9.91421 6 10.2892 5.83211 10.5607 5.56066M8.43934 5.56066C8.16789 5.28921 8 4.91421 8 4.5C8 3.67157 8.67157 3 9.5 3C10.3284 3 11 3.67157 11 4.5C11 4.91421 10.8321 5.28921 10.5607 5.56066M8.43934 5.56066L5.56066 8.43934M5.56066 8.43934C5.28921 8.16789 4.91421 8 4.5 8C3.67157 8 3 8.67157 3 9.5C3 10.3284 3.67157 11 4.5 11C5.32843 11 6 10.3284 6 9.5C6 9.08579 5.83211 8.71079 5.56066 8.43934ZM10.5607 5.56066L13.4393 8.43934M13.4393 8.43934C13.1679 8.71079 13 9.08579 13 9.5C13 10.3284 13.6716 11 14.5 11C15.3284 11 16 10.3284 16 9.5C16 9.08579 15.8321 8.71079 15.5607 8.43934M13.4393 8.43934C13.7108 8.16789 14.0858 8 14.5 8C14.9142 8 15.2892 8.16789 15.5607 8.43934M15.5607 8.43934L18.4393 5.56066M18.4393 5.56066C18.7108 5.83211 19.0858 6 19.5 6C20.3284 6 21 5.32843 21 4.5C21 3.67157 20.3284 3 19.5 3C18.6716 3 18 3.67157 18 4.5C18 4.91421 18.1679 5.28921 18.4393 5.56066Z" stroke="#6A6970" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                </ListItemIcon>
                <ListItemText primary={t('Industry')} />
                {open === '/industry' ? <ExpandLess /> : <ExpandMore />}
              </CustomListItemButton>
              <Collapse in={open === '/industry'} timeout="auto" unmountOnExit>
                <List component="div" disablePadding >
                  {menuIndustry?.map((m) => (
                    <CustomListItemButton key={m?.title} sx={{ pl: 4 }} isActive={(`/${path}` === `${m?.path_parent}`) && (String(category_id) === String(m?.id))} isActiveText={true} onClick={() => { navigate(m?.path); handleCloseMenu(); }}>
                      <ListItemIcon style={{ minWidth: '20px' }}>
                      </ListItemIcon>
                      <ListItemText className="text-item-menu" primary={m?.title || ''} />
                    </CustomListItemButton>
                  ))}
                </List>
              </Collapse>
            </List>
          </ul>
          <ul className="metismenu metismenu-bottom" id="menu" >
            <ButtonDanger
              onClick={() => { navigate('/service-introduction'); handleCloseMenu(); handleClick('/'); }}
              variant="outlined"
              text={t('Service Introduction')}
              className="py-2"
              isActive={`/${path}` === '/personal'}
              isIcon={true}
              style={{ fontSize: '14px' }}
            />
          </ul>
        </div>
      </div>


    </div>
  );
};

export default SideBar;

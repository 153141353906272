import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Message Socket
import MessageReducer from "./message/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ProfileReducer from "./auth/profile/reducer";
import RankingReducer from "./ranking/reducer";
import MasterReducer from "./master/reducer";

const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Message: MessageReducer,
    Login: LoginReducer,
    Account: AccountReducer,
    Profile: ProfileReducer,
    Ranking: RankingReducer,
    Master: MasterReducer
});

export default rootReducer;
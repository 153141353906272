import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const CustomSkeleton = ({children = <></>, isLoading = false , isDark = false, height = 30}) => {
    const baseColor = isDark ? '#202020' : '#e0e0e0';
   const highlightColor = isDark ? '#444' : '#f5f5f5';
  return (
    <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
        { isLoading ? <Skeleton height={height} /> : children }
    </SkeletonTheme>
  );
}

export default React.memo(CustomSkeleton);

import { getAccessToken, getRefreshToken, removeAccessToken, removeRefreshToken, setAccessToken, setRefreshToken } from '../helpers/cookie';
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import authApi from './authApi';
import { push } from 'helpers/navigation';

let isRefreshing: boolean = false;

let failedQueue: any = [];

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'access-control-allow-headers': "*",
    'Content-Type': 'application/json',
  },
});

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const onRequest = (config: any) => {
  const accessToken = getAccessToken();
  if (accessToken) {
    config.metadata = { startTime: new Date() };
    config.timeout = 1000 * 60 * 10;
    config.headers["Authorization"] = "Bearer " + accessToken;
  }
  return config;
}

const onResponse = (response: any) => {
  const duration = Number(response.config?.metadata?.endTime || new Date()) - Number(response.config?.metadata?.startTime || 0);
  if (response.data?.data?.meta?.access_token?.token) {
    setAccessToken(response.data?.data?.meta?.access_token?.token);
    instance.defaults.headers.common["Authorization"] = "Bearer " + response.data?.data?.meta?.access_token?.token;
  }
  if (response.data?.data?.meta?.refresh_token?.token) {
    setRefreshToken(response.data?.data?.meta?.refresh_token?.token);
  }
  if (response.data?.data?.pagination) {
    response.data.data.pagination.duration = duration;
  }
  if(response.data){
    response.data.duration = duration;
  }
  return response.data;
}
const onResponseError = async (error: any) => {
  const originalRequest = error.config;
  const status = error.response?.status;
  if ((status === 401) && (error.config.url === '/auth/users/refresh' || error.config.url === 'auth/users/refresh')) {
    removeAccessToken();
    removeRefreshToken();
    const currentUrl = window.location.pathname + window.location.search;// Get the current URL
    localStorage.setItem('referralUrl', currentUrl);
    localStorage.removeItem("authUser");
    isRefreshing = false;
    window.location.href = '/';
    return Promise.reject('Invalid credentials');
  };

  if ((status === 401) && !originalRequest._retry) {
    if (isRefreshing) {
      try {
        const token = await new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        });
        return instance(originalRequest);
      } catch (err) {
        return Promise.reject(err);
      }
    }

    originalRequest._retry = true;
    isRefreshing = true;

    return new Promise((resolve, reject) => {
      refreshToken()
        .then((access_token) => {
          processQueue(null, access_token);
          resolve(instance(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  } else {
    let message;
    switch (status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      case 422:
          message = error?.response?.data?.errors;
        break;
      default:
        message = error?.response?.data?.message || error?.message;
    }
    return Promise.reject(message);
  }
}

// intercepting to capture errors
instance.interceptors.request.use(onRequest, error => Promise.reject(error));
instance.interceptors.response.use(onResponse, onResponseError)

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string) => {
  instance.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from the given URL
   */
  get = (url: string, params?: any): Promise<AxiosResponse> => {
    let response: Promise<AxiosResponse>;

    let paramKeys: string[] = [];

    if (params) {
      Object.keys(params).map(key => {
        if (params[key]) {
          paramKeys.push(key + '=' + params[key]);
        }
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
      response = instance.get(`${url}?${queryString}`, params);
    } else {
      response = instance.get(`${url}`, params);
    }

    return response;
  };

  /**
   * Posts the given data to the URL
   */
  post = (url: string, data: any, config: any = null): Promise<AxiosResponse> => {
    return instance.post(url, data, config);
  };

  /**
   * Updates data
   */
  patch = (url: string, data: any): Promise<AxiosResponse> => {
    return instance.patch(url, data);
  };

  put = (url: string, data: any): Promise<AxiosResponse> => {
    return instance.put(url, data);
  };

  /**
   * Deletes data
   */
  delete = (url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> => {
    return instance.delete(url, { ...config });
  };
}

const getLoggedInUserInfo = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

const refreshToken = async () => {
  try {
    const res = await authApi.refresh({ refresh_token: getRefreshToken() });
    const newAccessToken = res?.data?.meta?.access_token?.token;
    setAccessToken(newAccessToken);
    return newAccessToken;
  } catch (e) {
    removeAccessToken();
    removeRefreshToken();
    localStorage.removeItem("authUser");
    window.location.href = '/';
    return null;
  }
};

export { APIClient, setAuthorization, getLoggedInUserInfo };


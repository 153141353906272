import React, { useReducer } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Button, Modal, Tab, Nav, Row, Col } from "react-bootstrap";
//import { SRLWrapper } from "simple-react-lightbox";

import LightGallery from 'lightgallery/react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

/// Image
import profile01 from "../../../../assets/images/profile/1.jpg";
import profile02 from "../../../../assets/images/profile/2.jpg";
import profile03 from "../../../../assets/images/profile/3.jpg";
import profile04 from "../../../../assets/images/profile/4.jpg";
import profile05 from "../../../../assets/images/profile/5.jpg";
import profile06 from "../../../../assets/images/profile/6.jpg";
import profile07 from "../../../../assets/images/profile/7.jpg";
import profile08 from "../../../../assets/images/profile/8.jpg";
import profile from "../../../../assets/images/profile/profile.png";

import trend_index from "../../../../assets/images/introduction/index/trend-index.jpeg";
import search_index from "../../../../assets/images/introduction/index/search-index.jpeg";
import viral_index from "../../../../assets/images/introduction/index/viral-index.jpeg";
import news_index from "../../../../assets/images/introduction/index/news-index.jpeg";
import participation_index from "../../../../assets/images/introduction/index/participation-index.jpeg";

import music_trend from "../../../../assets/images/introduction/trend/music-trend.jpeg";
import stock_trend from "../../../../assets/images/introduction/trend/stock-trend.jpeg";
import sport_trend from "../../../../assets/images/introduction/trend/sport-trend.jpeg";


import { useTranslation } from "react-i18next";

const galleryBlog = [
  { image: profile02 }, { image: profile03 },
  { image: profile04 }, { image: profile03 },
  { image: profile02 }, { image: profile04 },
];

const initialState = false;
const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'sendMessageOpen':
      return { ...state, sendMessage: true }
    case 'sendMessageClose':
      return { ...state, sendMessage: false }

    default:
      return state
  }
}

const tabData = [
  {
    name: "트랜드지수",
    icon: "home",
    image: trend_index,
    content:
      `랭키파이에서 제공하는 검색지수, 바이럴지수, 뉴스지수, 참여지수 등의 다양한 지수를 결합하여 <br/>
      독창적 알고리즘으로 현재 의 트렌드를 종합적으로 평가한 지수입니다. <br/>
    트렌드 지수를 통해 산업과 시장의 변화와 흐름을 한눈에 파악할 수 있습니다.`,
  },
  {
    name: "검색지수",
    icon: "user",
    image: search_index,
    content:
      `글로벌 포털에서 특정 단어나 문장을 얼마나 많이 검색을 했는지에 대한 지수로, <br/>
      대중의 관심과 수요 및 현시간의 이슈를 직접적으로 반영합니다. <br/>
      검색지수를 통해서 지금 이시간 가장 사람들이 관심을 가지고 있는 것에 대하여 알 수 있습니다. `,
  },
  {
    name: "바이럴지수",
    icon: "phone",
    image: viral_index,
    content:
      `소셜 미디어에 특정 단어나 문장이 얼마나 언급이 되는지에 대한 지수로, <br/>
      소셜 미디어에서의 대중의 관심과 인기 및 화제성을 반영합니다. <br/>
      소셜 미디어를 통한 실시간 이슈와 영향력을 한 눈에 파악하고 분석할 수 있는 지표가 됩니다.`,
  },

  {
    name: "뉴스지수",
    icon: "envelope",
    image: news_index,
    content:
      `온라인 뉴스 매체에서 특정 단어나 문장의 주제가 얼마나 보도되었는지를 나타내는 지수로, <br/>
      이슈와 사건에 대한 언론 미디어의 관심도를 반영합니다. <br/>
      온라인 매체의 영향력을 통한 브랜드의 인지도와 신뢰도에 대한 지표를 한 눈에 파악할 수 있습니다.`
  },
  {
    name: "참여지수",
    icon: "envelope",
    image: participation_index,
    content:
      `사용자의 관심도와 참여도가 직접 트렌드에 반영되는 지수입니다. <br/>
      상품의 판매량이나 이슈에 대한 댓글이나 리뷰 등의 사용자 반응과 투표 등의 참여 방법을 통하여 사용자들의 활동 여부와 관심도를 파악할 수 있습니다.`
  },
];

const tabData2 = [
  {
    name: "연예",
    icon: "home",
    image: music_trend,
    content:
      `랭키파이의 연예 트렌드는 방송과 같은 대중 미디어를 통하여 영화, 드라마, 음악 등의 대중들에게 보여주는 모든 활동 영역을 포괄적으로 포함한다. <br/>
      미디어 프로그램을 비롯하여 활동하는 대중 문화인 전반의 이슈와 관심도를 한 눈에 볼 수 있는 랭키파이 대표 트렌드이다. `,
  },
  {
    name: "스포츠",
    icon: "user",
    image: sport_trend,
    content:
      `랭키파이의 스포츠 트렌드는 국내외 인기 스포츠 종목 뿐만 아니라 비인기 종목까지의 스포츠 산업 전반에 걸쳐있는 모든 영역을 포함한다. <br/>
      스포츠에 참여하는 개인, 팀, 기업 등을 모두 망라하여 스포츠와 관련된 이슈와 관심도를 한 눈에 파악할 수 있는 랭키파이 대표 트렌드이다.  `,
  },
  {
    name: "주식",
    icon: "phone",
    image: stock_trend,
    content:
      `랭키파이의 주식 트렌드는 전세계 주요 주식 시장의 종목 뿐만 아니라 협력사, 투자사 등 투자 활동의 전반에 걸쳐있는 모든 영역을 포함한다. <br/> 
      대한민국 주식 시장 뿐만 아니라 나스닥 ,자스닥 등의 글로벌 경제 지표에 대한 이슈와 관심도를 한 눈에 파악할 수 있는 랭키파이 대표 트렌드이다. `,
  },
];
const ServiceIntroduction = () => {
  const { t, i18n } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);


  return (
    <>
      {/* row */}
      <div className="row">
        <div className="col-lg-12 px-0 px-sm-3">
          <div className="profile card card-body p-0 rounded-0">
            <div className="profile-head">
              <div className="photo-content ">
                <div className="rounded-0 cover-photo-introduction d-flex flex-column flex-sm-row justify-content-center align-items-center">
                  <div>
                    <svg width="230" height="72" viewBox="0 0 230 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M-0.000244141 19.3174H46.5367V45.3379C46.5367 48.7209 45.8775 52.072 44.5954 55.2057C41.7441 62.1751 36.0159 67.5882 28.8711 70.0649L23.7459 71.8416C23.1447 72.05 22.4909 72.0533 21.8876 71.8508L17.0528 70.2286C9.3932 67.6586 3.45009 61.5731 1.09095 53.8846C0.36749 51.5267 -0.000244141 49.0753 -0.000244141 46.6101V19.3174ZM5.72738 25.0128V46.6101C5.72738 48.512 6.01109 50.4034 6.56925 52.2224C8.38936 58.1543 12.9745 62.8493 18.884 64.8321L22.7881 66.142L26.9856 64.6869C32.5765 62.7488 37.0589 58.5131 39.29 53.0595C40.2932 50.6073 40.8091 47.9851 40.8091 45.3379V25.0128H5.72738Z" fill="white" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5287 30.7319H25.8077C30.0958 30.7319 33.5719 34.2149 33.5719 38.5113C33.5719 42.8077 30.0958 46.2906 25.8077 46.2906H12.5287V30.7319ZM18.3338 36.5483V40.4743H25.8077C26.8898 40.4743 27.7669 39.5954 27.7669 38.5113C27.7669 37.4271 26.8898 36.5483 25.8077 36.5483H18.3338Z" fill="white" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5287 40.4741H20.946C28.1596 40.4741 34.0073 46.3332 34.0073 53.5608H28.2023C28.2023 49.5455 24.9535 46.2904 20.946 46.2904H18.3338V53.5608H12.5287V40.4741Z" fill="white" />
                      <path d="M33.4265 4.75119V8.72417H27.0058L27.9733 4.09585L23.2677 0L18.738 4.09585L19.7055 8.76513H13.2848V4.79215L5.36884 1.43355L6.77611 14.0488H23.3556H39.9351L41.1665 1.39259L33.4265 4.75119Z" fill="#F6B40C" />
                      <path d="M221.478 33.1147H230L220.328 54.6897C217.217 61.6305 214.405 64.0975 208.44 64.0975H205.713V57.4493H207.929C210.954 57.4493 211.849 56.6549 212.914 53.8535L203.412 33.1147H212.019L216.919 45.5747L221.478 33.1147Z" fill="white" />
                      <path d="M202.792 33.4911V39.5956H197.636V55.6932H189.711V39.5956H185.918L185.876 33.4911H189.796V31.3587C189.796 26.8011 192.352 24.167 197.21 24.167H202.749V30.1461H199.255C198.062 30.1461 197.38 30.8151 197.38 31.9858V33.4911H202.792Z" fill="white" />
                      <path d="M174.553 30.3133V23.707H182.393V30.3133H174.553ZM174.511 55.6932V33.1147H182.436V55.6932H174.511Z" fill="white" />
                      <path d="M172.479 55.6937H163.105L157.352 46.1606V55.6937H149.427V24.9619H157.352V41.9794L162.849 33.1153H171.754L164.553 43.9028L172.479 55.6937Z" fill="white" />
                      <path d="M135.515 32.4873C140.841 32.4873 144.377 36.2086 144.377 41.9368V55.693H136.452V43.0239C136.452 40.3898 135.43 39.0936 133.427 39.0936C130.956 39.0936 129.55 40.7661 129.55 43.6511V55.693H121.624V33.1145H127.206L128.271 35.456C129.976 33.5744 132.532 32.4873 135.515 32.4873Z" fill="white" />
                      <path d="M110.7 33.1148H116.623V55.6933H110.487L109.805 54.1044C107.931 55.6097 105.545 56.4459 102.86 56.4459C95.8298 56.4459 90.8019 51.4285 90.8019 44.404C90.8019 37.4214 95.8298 32.4458 102.86 32.4458C105.587 32.4458 108.016 33.3239 109.891 34.8709L110.7 33.1148ZM103.925 49.5887C106.908 49.5887 109.081 47.3727 109.081 44.404C109.081 41.4772 106.908 39.303 103.925 39.303C100.943 39.303 98.7698 41.4772 98.7698 44.404C98.7698 47.3727 100.943 49.5887 103.925 49.5887Z" fill="white" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M88.0951 36.6784C88.0951 31.2719 83.6289 26.8892 78.1194 26.8892H60.8557V39.4988V46.4676V56.1574H67.9571V46.4674H71.6944C77.148 46.4674 81.5691 50.8058 81.5691 56.1574H88.6705C88.6705 51.6819 86.872 47.6186 83.9454 44.6255C86.4589 42.8481 88.0951 39.9507 88.0951 36.6784ZM80.9937 36.6788C80.9937 38.2365 79.7069 39.4993 78.1194 39.4993H73.0383H67.9571V33.8583H78.1194C79.7069 33.8583 80.9937 35.1211 80.9937 36.6788Z" fill="white" />
                    </svg>
                  </div>
                  <div>
                    <div className="fs-24 text-white pt-2 mt-0 mt-sm-3 ms-0 ms-sm-4">세상의 모든 랭킹, 랭키파이</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="post-details">
                <h3 className="mb-2">
                  랭키파이 지수 소개
                  <svg width="26" height="26" viewBox="0 0 40 40" className="ms-1 h3" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 8V32H31.9989M13.9997 23.0002L19.2495 17.7502L22.9993 21.5002L31.2489 13.2502M25.6363 12.5H32V18.864" stroke="var(--text-dark)" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </h3>

                <p style={{ fontSize: '0.9rem' }}>
                  랭키파이는 주식회사 티디아이플레이의 다년간의 빅데이터 노하우로 급변하는 트렌드를 정밀하게 분석하여
                  <br />
                  독창적인 알고리즘을 바탕으로 한 지수(INDEX)로 제공합니다.
                </p>
                <div className="custom-tab-1">
                  <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                    <div className="overflow-x-auto overflow-y-hidden">
                      <Nav as="ul" className="nav-tabs w-100 justify-content-space-between" style={{ minWidth: '500px' }}>
                        {tabData.map((data, i) => (
                          <Nav.Item as="li" key={i} className="flex-grow-1 text-center">
                            <Nav.Link eventKey={data.name.toLowerCase()} className="px-2 px-md-5" style={{ fontSize: '1rem' }}>
                              {/* <i className={`la la-${data.icon} me-2`} /> */}
                              {data.name}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </div>
                    <Tab.Content className="pt-4 mb-2">
                      {tabData.map((data, i) => (
                        <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                          <Row>
                            <Col sm="4" md="3">
                              <img alt={data?.name} src={data?.image} loading="lazy" className="image-introduction" />
                            </Col>
                            <Col sm="8" md="9" className="text-start d-flex justify-content-start align-items-center mt-3 mt-sm-0">
                              <p dangerouslySetInnerHTML={{ __html: t(data?.content) }} style={{ fontSize: '0.9rem' }} ></p>
                            </Col>
                          </Row>
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="post-details">
                <h3 className="mb-2">
                  랭키파이 대표 트렌드
                  <svg width="26" height="26" viewBox="0 0 40 40" className="ms-1 h3" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Frame 92">
                      <path id="Icon" d="M19.9996 20.1221V19.9999M32.1366 32.1368C29.5695 34.7039 22.0547 31.3511 15.3518 24.6481C8.64882 17.9451 5.29605 10.4302 7.86313 7.86313C10.4302 5.29603 17.945 8.64883 24.648 15.3518C31.3509 22.0548 34.7037 29.5697 32.1366 32.1368ZM7.86339 32.1369C5.29631 29.5698 8.64908 22.0549 15.352 15.3519C22.055 8.64891 29.5698 5.29611 32.1369 7.86321C34.704 10.4303 31.3512 17.9452 24.6482 24.6482C17.9453 31.3512 10.4305 34.704 7.86339 32.1369Z" stroke="var(--text-dark)" stroke-width="3" stroke-linecap="round" />
                    </g>
                  </svg>
                </h3>
                <p style={{ fontSize: '0.9rem' }}>
                  랭키파이는 지수를 바탕으로 데이터를 실시간 분석하여 최적의 마케팅 전략을 제안합니다.<br />
                  다양한 데이터 소스를 통합하여 기존에 경험할 수 없었던 다양한 인사이트를 제공합니다.
                </p>
                <div className="custom-tab-1">
                  <Tab.Container defaultActiveKey={tabData2[0].name.toLowerCase()}>
                    <Nav as="ul" className="nav-tabs w-100 justify-content-space-between">
                      {tabData2.map((data, i) => (
                        <Nav.Item as="li" key={i} className="flex-grow-1 text-center">
                          <Nav.Link eventKey={data.name.toLowerCase()} className="px-2 px-md-5" style={{ fontSize: '1rem' }}>
                            {/* <i className={`la la-${data.icon} me-2`} /> */}
                            {data.name}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                    <Tab.Content className="pt-4 mb-2">
                      {tabData2.map((data, i) => (
                        <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                          <Row>
                            <Col sm="4" md="3">
                              <img alt={data?.name} src={data?.image} loading="lazy" className="image-introduction" />
                            </Col>
                            <Col sm="8" md="9" className="text-start d-flex justify-content-start align-items-center mt-3 mt-sm-0">
                              <p dangerouslySetInnerHTML={{ __html: t(data?.content) }} style={{ fontSize: '0.9rem' }}></p>
                            </Col>
                          </Row>
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="post-details">
                <h3 className="mb-5">
                  랭키파이 FAQ
                  <svg width="26" height="26" viewBox="0 0 40 40" className="ms-1 h3" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M30.7778 17.3235V10.1765C30.7778 8.42215 29.3351 7 27.5556 7H8.22222C6.44264 7 5 8.42216 5 10.1765V22.6061C5 24.3605 6.44264 25.7826 8.22222 25.7826H10.744V32.4118L17.4686 25.7826H17.8889M26.2071 29.8568L30.41 34V29.8568H30.7778C32.5574 29.8568 34 28.4346 34 26.6803V21.2941C34 19.5398 32.5574 18.1176 30.7778 18.1176H21.1111C19.3315 18.1176 17.8889 19.5398 17.8889 21.2941V26.6803C17.8889 28.4346 19.3315 29.8568 21.1111 29.8568H26.2071Z" stroke="var(--text-dark)" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </h3>
                <div>
                  <div className="d-flex mb-2 mb-md-3 align-items-center">
                    <div>
                      <svg width="21" height="25" viewBox="0 0 25 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.707 17.1758H15.2422L17.1758 19.6719C18.3887 18.248 19.0742 16.1035 19.0742 13.2734C19.0742 7.82422 16.5078 4.87109 12.5703 4.87109C8.63281 4.87109 6.03125 7.82422 6.03125 13.2734C6.03125 18.7227 8.63281 21.6758 12.5703 21.6758C13.0801 21.6758 13.5723 21.623 14.0469 21.5352L10.707 17.1758ZM24.4531 13.2734C24.4531 17.8086 22.9062 21.3066 20.4102 23.5039L24.0312 28.1094H19.0742L17.0352 25.543C15.6641 26.0879 14.1523 26.3516 12.5703 26.3516C5.78516 26.3516 0.652344 21.5352 0.652344 13.2734C0.652344 4.97656 5.78516 0.195312 12.5703 0.195312C19.2852 0.195312 24.4531 4.97656 24.4531 13.2734Z" fill="#6A6970" />
                      </svg>
                    </div>
                    <div className="ps-3">
                      <p className="mb-0 " style={{ fontSize: '1rem' }} >
                        랭키파이의 데이터는 어디에서 가져오나요?
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div>
                      <svg width="23" height="23" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.39844 26H0.703125L9.52734 0.546875H16.3125L25.1016 26H19.4414L17.543 20.1289H8.33203L6.39844 26ZM9.66797 16.0156H16.207L13.0078 6.27734H12.832L9.66797 16.0156Z" fill="black" />
                      </svg>
                    </div>
                    <div className="ps-3">
                      <p className="mb-0" style={{ fontSize: '0.9rem' }}>
                        랭키파이는 주식회사 티디아이플레이와 제휴된 협력사 및 언론 매체, 주요 글로벌 포털의 유료 데이터, 공공 데이터 등을 수집한 후 빅데이터화하여 정밀 분석에 활용됩니다.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 my-2 my-md-5"><hr /></div>
                  <div className="d-flex mb-2 mb-md-3 align-items-center">
                    <div>
                      <svg width="21" height="25" viewBox="0 0 25 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.707 17.1758H15.2422L17.1758 19.6719C18.3887 18.248 19.0742 16.1035 19.0742 13.2734C19.0742 7.82422 16.5078 4.87109 12.5703 4.87109C8.63281 4.87109 6.03125 7.82422 6.03125 13.2734C6.03125 18.7227 8.63281 21.6758 12.5703 21.6758C13.0801 21.6758 13.5723 21.623 14.0469 21.5352L10.707 17.1758ZM24.4531 13.2734C24.4531 17.8086 22.9062 21.3066 20.4102 23.5039L24.0312 28.1094H19.0742L17.0352 25.543C15.6641 26.0879 14.1523 26.3516 12.5703 26.3516C5.78516 26.3516 0.652344 21.5352 0.652344 13.2734C0.652344 4.97656 5.78516 0.195312 12.5703 0.195312C19.2852 0.195312 24.4531 4.97656 24.4531 13.2734Z" fill="#6A6970" />
                      </svg>
                    </div>
                    <div className="ps-3">
                      <p className="mb-0 " style={{ fontSize: '1rem' }} >
                        다른 랭킹사이트와 다른 랭키파이의 차별점은 무엇인가요?
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div>
                      <svg width="23" height="23" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.39844 26H0.703125L9.52734 0.546875H16.3125L25.1016 26H19.4414L17.543 20.1289H8.33203L6.39844 26ZM9.66797 16.0156H16.207L13.0078 6.27734H12.832L9.66797 16.0156Z" fill="black" />
                      </svg>
                    </div>
                    <div className="ps-3">
                      <p className="mb-0" style={{ fontSize: '0.9rem' }}>
                        랭키파이는 트렌드 지수를 기반으로 실시간 트렌드의 변화를 알 수 있습니다. 사용자의 실시간 참여를 통하여 트렌드를 리드하고 변화시킬 수 있습니다.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 my-2 my-md-5"><hr /></div>
                  <div className="d-flex mb-2 mb-md-3 align-items-center">
                    <div>
                      <svg width="21" height="25" viewBox="0 0 25 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.707 17.1758H15.2422L17.1758 19.6719C18.3887 18.248 19.0742 16.1035 19.0742 13.2734C19.0742 7.82422 16.5078 4.87109 12.5703 4.87109C8.63281 4.87109 6.03125 7.82422 6.03125 13.2734C6.03125 18.7227 8.63281 21.6758 12.5703 21.6758C13.0801 21.6758 13.5723 21.623 14.0469 21.5352L10.707 17.1758ZM24.4531 13.2734C24.4531 17.8086 22.9062 21.3066 20.4102 23.5039L24.0312 28.1094H19.0742L17.0352 25.543C15.6641 26.0879 14.1523 26.3516 12.5703 26.3516C5.78516 26.3516 0.652344 21.5352 0.652344 13.2734C0.652344 4.97656 5.78516 0.195312 12.5703 0.195312C19.2852 0.195312 24.4531 4.97656 24.4531 13.2734Z" fill="#6A6970" />
                      </svg>
                    </div>
                    <div className="ps-3">
                      <p className="mb-0 " style={{ fontSize: '1rem' }} >
                        랭키파이 데이터 제휴나 문의는 어떻게 하나요?
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-start  mb-5">
                    <div >
                      <svg width="23" height="23" viewBox="0 0 26 26" className="mt-1" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.39844 26H0.703125L9.52734 0.546875H16.3125L25.1016 26H19.4414L17.543 20.1289H8.33203L6.39844 26ZM9.66797 16.0156H16.207L13.0078 6.27734H12.832L9.66797 16.0156Z" fill="black" />
                      </svg>
                    </div>
                    <div className="ps-3">
                      <p className="mb-0" style={{ fontSize: '0.9rem' }}>
                        랭키파이는 주식회사 티디아이플레이에서 운영하는 서비스입니다. <br />
                        - 이메일 문의 : info@rankify.best <br />
                        - 전화 문의 : 070-7798-1644 <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>

  );
};

export default ServiceIntroduction;
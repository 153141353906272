import { createSlice } from "@reduxjs/toolkit";
import { ICategory } from "api/types/_category";
import { allCategoryMenu } from "./thunk";
export interface IState {
  menu: ICategory[] | null,
  isMenuLoading: boolean,
  isMenuSuccess: boolean,
  error: any,
};

export const initialState: IState = {
  menu: null,
  isMenuLoading: false,
  isMenuSuccess: false,
  error: {},
};

const RankingSlice = createSlice({
  name: "Master",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(allCategoryMenu.pending, (state: IState, action: any) => {
      state.isMenuLoading = true
    });
    builder.addCase(allCategoryMenu.fulfilled, (state: IState, action: any) => {
      state.menu = action.payload.data;
      state.isMenuSuccess = true;
      state.isMenuLoading = false;
    });
    builder.addCase(allCategoryMenu.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMenuSuccess = false;
      state.isMenuLoading = false;
    });
  },
});

export const {} = RankingSlice.actions;

export default RankingSlice.reducer;
import { createAsyncThunk } from "@reduxjs/toolkit";
import rankingApi from "api/rankingApi";
import { formatQueryParams } from "helpers/format";

export const rankingCategory = async(params: any = {}) => {
  try{
    const response = await rankingApi.rankingCategory(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
};
export const rankingCategories = createAsyncThunk("Ranking Categories" , async (params: any = {}) => {
  try{
    const response = await rankingApi.rankingCategory(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const rankingKeywords = createAsyncThunk("Ranking Keywords By Category" , async (params: any = {}) => {
  try{
    const response = await rankingApi.rankingKeywordByCategory(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getRankingKeywordsByNormal = async (params: any = {}) => {
  try{
    const response = await rankingApi.rankingKeywordByCategory(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
};

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Spinner } from 'react-bootstrap';

const CustomizedButtons = styled(Button)(({ variant }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: '0.813rem',
  padding: '0.57rem 1rem',
  borderRadius: '0.5rem',
  flex: '1 0 auto',
  display: 'grid',
  alignItems: 'center',
  alignContent: 'center',
  fontFamily: [
    'NanumSquare',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  ...(variant === 'contained' && {
    backgroundColor: '#020220',
    borderColor: '#020220',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#1c1c3c',
      borderColor: '#1c1c3c',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#14142b',
      borderColor: '#14142b',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(2, 2, 32, 0.5)',
    },
  }),
  ...(variant === 'outlined' && {
    backgroundColor: 'transparent',
    borderColor: '#020220',
    color: '#020220',
    '&:hover': {
      backgroundColor: 'rgba(2, 2, 32, 0.1)',
      borderColor: '#1c1c3c',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgba(20, 20, 43, 0.1)',
      borderColor: '#14142b',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(2, 2, 32, 0.5)',
    },
  }),
}));

export default function ButtonWarning({ text, isLoading, variant = 'contained', ...props }: any) {
  return (
    <CustomizedButtons variant={variant} {...props}>
      {isLoading ? <div style={{ transform: 'translateY(3px)' }}><Spinner size="sm"></Spinner></div> : text}
    </CustomizedButtons>
  );
}

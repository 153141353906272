import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";

import { ICategory } from "./types/_category";
import { PaginationResponse, ResponseData } from "./types/_public";
import { ITicket } from "./types/_vote";
const api = new APIClient();

const path = '/masters';
const masterApi = {
  allCategoryMenu(params: any): Promise<AxiosResponse<ResponseData<ICategory[]> & PaginationResponse, any>> {
    const url = `${path}/categories/simple`
    return api.get(url, params)
  },
  allCategories(params: any): Promise<AxiosResponse<ICategory[], any>> {
    const url = `${path}/categories`
    return api.get(url, params)
  },
  allTickets(params: any): Promise<AxiosResponse<ITicket[], any>> {
    const url = `${path}/tickets`
    return api.get(url, params)
  },
}
export default masterApi

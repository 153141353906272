import React, { useContext, useEffect, useRef, useState } from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Button, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

/// Image
import profile from "../../../assets/images/user.jpg";
import avatar from "../../../assets/images/avatar/9.jpg";

import { ThemeContext } from "../../../context/ThemeContext";
import Logout from "./Logout";
import { SVGICON } from "../../constant/theme";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "./LanguageDropdown";
import { createSelector } from "reselect";
import { MenuList, MenuListBottom } from "./Menu";
import { CATEGORY_TYPE } from "helpers/constans";
import moment from "moment";
import ButtonPrimary from "ui/components/buttons/ButtonPrimary";

const listBlog = [
  { icon: SVGICON.LtcSvgIcon, name: 'LTC in DexignLab' },
  { icon: SVGICON.BtcSvgIcon, name: 'BTC/USD in DexignLab' },
  { icon: SVGICON.EthSvgIcon, name: 'ETH/USD Dlab ' },
  { icon: SVGICON.BtcSvgIcon, name: 'BTC/USD in DexignLab' },
  { icon: SVGICON.EthSvgIcon, name: 'ETH/USD Dlab ' },
  { icon: SVGICON.LtcSvgIcon, name: 'LTC in DexignLab' },
];

const Header = ({ onNote }: any) => {
  const { t, i18n } = useTranslation();
  const headerRef = useRef(null);
  const [headerFix, setheaderFix] = useState(false);
  const user: any = useSelector((state: any) => state?.Login);
  const navigate = useNavigate();

  const [menuList, setMenuList] = useState([...MenuList, ...MenuListBottom] || []);

  const selectLayoutState = (state: any) => state.Master;

  const MasterProperties = createSelector(
    selectLayoutState,
    (state) => ({
      menu: state.menu,
      isMenuSuccess: state.isMenuSuccess,
      isMenuLoading: state.isMenuLoading,
      error: state.error,
    })
  );
  const { menu, isMenuLoading } = useSelector(MasterProperties);

  function CommanScroll() {
    setheaderFix(window.scrollY > 50);
  }

  useEffect(() => {
    window.addEventListener("scroll", CommanScroll);
    return () => {
      window.removeEventListener("scroll", CommanScroll)
    }
  }, [])

  useEffect(() => {
    setMenuList((_prev) => {
      const m = menu?.map((item: any) => ({
        title: String(item?.type) === String(CATEGORY_TYPE.STOCK) ? t('Corporation Rankify') : `${item?.name || ''} ${t('Rankify_KR')}`,
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-symbols-outlined">dashboard</i>,
        path: `/category/${item?.id}`,
        to: `/category/${item?.id}?is_clear=1`,
      }));
      return [...MenuList, ...MenuListBottom].concat(m || []);
    });
  }, [JSON.stringify(menu)]);

  const { background, changeBackground,
    headWallet, setHeadWallet } = useContext(ThemeContext);
  const handleThemeMode = () => {
    if (background.value === 'dark') {
      changeBackground({ value: "light", label: "Light" });
    } else {
      changeBackground({ value: "dark", label: "Dark" });
    }
  }

  const pathUrl = window.location.pathname.split("/");
  const path = pathUrl[pathUrl.length - 1];
  const presss = menuList?.reduce((arr: any, item: any) => { return item?.content ? arr.concat([item, ...item?.content]) : arr.concat([item]) }, []);
  const menuActive = presss?.find((item: any) => { return (item?.path === window.location.pathname) });
  const nameMenuActive: any = `${`<span class="text-sub-disabled">${t('Rankify_KR')} </span> <span class="text-sub-disabled mx-2">|</span>`} ${`<span class="text-sub-active">${t(menuActive?.title || '')}</span>`}`

  const pathtitle = window.location.pathname.split("/");
  const name = pathtitle[pathtitle.length - 1].split("-");

  function handleActiveWallet() {
    setHeadWallet(!headWallet)
  }
  const goToLogin = () => {
    const currentUrl = window.location.pathname + window.location.search; // Get the current URL
    localStorage.setItem('referralUrl', currentUrl);
    navigate('/login');
  };

  const goToSignUp = () => {
    const currentUrl = window.location.pathname + window.location.search; // Get the current URL
    localStorage.setItem('referralUrl', currentUrl);
    navigate('/sign-up');
  };

  const walletActive = window.matchMedia("(max-width:100rem)").matches
  useEffect(() => {
    if (walletActive) {
      setHeadWallet(true)
    } else {
      setHeadWallet(false)
    }
  }, [walletActive])

  useEffect(() => {
    const header: any = headerRef.current;
    if (header) {
      const headerHeight = header.offsetHeight;
      document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
    }
  }, []);

  return (
    <>
      <div className={`header ${path === "dashboard" || path === "index-2" ? 'home' : ''} ${headerFix ? 'is-fixed' : ''}`} id="header-page" >
        <div className="header-content" ref={headerRef}>
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left">
              </div>
              <ul className="navbar-nav header-right ">
                <li className="nav-item">
                  <LanguageDropdown />
                </li>
                {/* <li className="nav-item dropdown notification_dropdown">
                  <Link to={"#"}
                    className={`nav-link bell dz-theme-mode ${background.value === "dark" ? "active" : ""}`}
                    onClick={() => handleThemeMode()}
                  >
                    {SVGICON.LightSvgIcon}
                    {SVGICON.DarkSvgIcon}
                  </Link>
                </li> */}

                {/* <li className={`nav-item dropdown notification_dropdown ${path === "dashboard" || path === "index-2" ? '' : 'd-none'}`}>
                  <Link to={"#"} className="nav-link  menu-wallet"
                    // onClick={()=>setHeadWallet(!headWallet)}
                    onClick={handleActiveWallet}
                  >
                    {SVGICON.WalletSvgIcon}
                  </Link>
                </li> */}

                {/* <Dropdown as="li" className="nav-item notification_dropdown">
                  <Dropdown.Toggle className="nav-link i-false c-pointer icon-bell-effect" variant="" as="a">
                    {SVGICON.HeadBell}
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end" className="mt-3">
                    <div className="widget-media dz-scroll p-3 height380" style={{ height: "380px" }}>
                      <ul className="timeline">
                        <li>
                          <div className="timeline-panel">
                            <div className="media me-2">
                              <img alt="images" width={50} src={avatar} />
                            </div>
                            <div className="media-body">
                              <h6 className="mb-1">Dr sultads Send you Photo</h6>
                              <small className="d-block">
                                29 July 2020 - 02:26 PM
                              </small>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="timeline-panel">
                            <div className="media me-2 media-info">KG</div>
                            <div className="media-body">
                              <h6 className="mb-1">
                                Resport created successfully
                              </h6>
                              <small className="d-block">
                                29 July 2020 - 02:26 PM
                              </small>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="timeline-panel">
                            <div className="media me-2 media-success">
                              <i className="fa fa-home" />
                            </div>
                            <div className="media-body">
                              <h6 className="mb-1">Reminder : Treatment Time!</h6>
                              <small className="d-block">
                                29 July 2020 - 02:26 PM
                              </small>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="timeline-panel">
                            <div className="media me-2">
                              <img alt="" width={50} src={avatar} />
                            </div>
                            <div className="media-body">
                              <h6 className="mb-1">Dr sultads Send you Photo</h6>
                              <small className="d-block">
                                29 July 2020 - 02:26 PM
                              </small>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="timeline-panel">
                            <div className="media me-2 media-danger">KG</div>
                            <div className="media-body">
                              <h6 className="mb-1">
                                Resport created successfully
                              </h6>
                              <small className="d-block">
                                29 July 2020 - 02:26 PM
                              </small>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="timeline-panel">
                            <div className="media me-2 media-primary">
                              <i className="fa fa-home" />
                            </div>
                            <div className="media-body">
                              <h6 className="mb-1">Reminder : Treatment Time!</h6>
                              <small className="d-block">
                                29 July 2020 - 02:26 PM
                              </small>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <Link className="all-notification" to="#">
                      See all notifications <i className="ti-arrow-right" />
                    </Link>
                  </Dropdown.Menu>
                </Dropdown> */}
                {/* <Dropdown as="li" className="nav-item dropdown notification_dropdown">
                  <Dropdown.Toggle variant="" as="a" className="nav-link bell bell-link i-false c-pointer"
                    onClick={() => onNote()}
                  >
                    {SVGICON.HeadMessage}
                  </Dropdown.Toggle>
                </Dropdown> */}
                {/* <Dropdown as="li" className="nav-item  notification_dropdown">
                  <Dropdown.Toggle variant="" as="a"
                    className="nav-link  ai-icon i-false c-pointer"
                  >
                    {SVGICON.HeadNotification}
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end" className="mt-3 dropdown-menu-end">
                    <div className="widget-timeline dlab-scroll style-1 p-3 height370">
                      <ul className="timeline">
                        <li>
                          <div className="timeline-badge primary" />
                          <Link
                            className="timeline-panel c-pointer text-muted"
                            to="#"
                          >
                            <span>10 minutes ago</span>
                            <h6 className="mb-0">
                              Youtube, a video-sharing website, goes live{" "}
                              <strong className="text-primary">$500</strong>.
                            </h6>
                          </Link>
                        </li>
                        <li>
                          <div className="timeline-badge info"></div>
                          <Link
                            className="timeline-panel c-pointer text-muted"
                            to="#"
                          >
                            <span>20 minutes ago</span>
                            <h6 className="mb-0">
                              New order placed{" "}
                              <strong className="text-info">#XF-2356.</strong>
                            </h6>
                            <p className="mb-0">
                              Quisque a consequat ante Sit amet magna at
                              volutapt...
                            </p>
                          </Link>
                        </li>
                        <li>
                          <div className="timeline-badge danger"></div>
                          <Link
                            className="timeline-panel c-pointer text-muted"
                            to="#"
                          >
                            <span>30 minutes ago</span>
                            <h6 className="mb-0">
                              john just buy your product{" "}
                              <strong className="text-warning">Sell $250</strong>
                            </h6>
                          </Link>
                        </li>
                        <li>
                          <div className="timeline-badge success"></div>
                          <Link
                            className="timeline-panel c-pointer text-muted"
                            to="#"
                          >
                            <span>15 minutes ago</span>
                            <h6 className="mb-0">
                              StumbleUpon is acquired by eBay.{" "}
                            </h6>
                          </Link>
                        </li>
                        <li>
                          <div className="timeline-badge warning"></div>
                          <Link
                            className="timeline-panel c-pointer text-muted"
                            to="#"
                          >
                            <span>20 minutes ago</span>
                            <h6 className="mb-0">
                              Mashable, a news website and blog, goes live.
                            </h6>
                          </Link>
                        </li>
                        <li>
                          <div className="timeline-badge dark"></div>
                          <Link
                            className="timeline-panel c-pointer text-muted"
                            to="#"
                          >
                            <span>20 minutes ago</span>
                            <h6 className="mb-0">
                              Mashable, a news website and blog, goes live.
                            </h6>
                          </Link>
                        </li>
                      </ul>

                    </div>
                  </Dropdown.Menu>
                </Dropdown> */}

                {!!user?.user?.email ? <Dropdown as="li" className="nav-item header-profile2">
                  <Dropdown.Toggle href={"" as any} className="nav-link i-false me-0" as="div">
                    <div className="header-info2 d-flex align-items-center">
                      {/* <div className="d-flex align-items-center sidebar-info">
                        <div>
                          <h5 className="mb-0">{user?.user?.name || 'admin'}</h5>
                          <span className="d-block text-end">{user?.user?.email || 'rankify@gmail.com'}</span>
                        </div>
                      </div> */}
                      <AccountCircleIcon sx={{ height: '36px', width: '36px' }} />
                      {/* <img src={avatar} alt="profile"  /> */}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end" className="mt-3 dropdown-menu dropdown-menu-right" style={{ width: '300px' }}>
                    <Dropdown.Item className="dropdown-item ai-icon icon-bell-effect d-flex align-items-center">
                      {SVGICON.ProfileSvgIcon}
                      <div>
                        <div className="ms-2" style={{ fontSize: '13px' }}><strong>{user?.user?.name || 'admin'}</strong></div>
                        <div className="ms-2" style={{ fontSize: '13px' }}>{user?.user?.email || 'rankify@gmail.com'} </div>
                      </div>
                    </Dropdown.Item>
                    <hr className="my-2"></hr>
                    <Dropdown.Item className="dropdown-item ai-icon">
                      <Link to={"/service-introduction"} className="d-flex align-items-center">
                        <button className="btn p-0" style={{ border: 0 }}>
                          <ErrorOutlineOutlinedIcon sx={{ width: 24, height: 24 }} />
                          <span className="ms-2" style={{ fontSize: '13px' }}>{t('Service Introduction')}</span>
                        </button>
                      </Link>
                    </Dropdown.Item>

                    {/* <Link to={"/app-profile"} className="dropdown-item ai-icon ">
                      {SVGICON.MessageSvgIcon}
                      <span className="ms-2">Message </span>
                    </Link>
                    <Link to={"/email-inbox"} className="dropdown-item ai-icon">
                      {SVGICON.NotificationIcon}
                      <span className="ms-2">Notification </span>
                    </Link>
                    <Link to={"#"} className="dropdown-item ai-icon ">
                      {SVGICON.SettingSvgIcon}
                      <span className="ms-2">Settings </span>
                    </Link> */}
                    <Logout />
                  </Dropdown.Menu>
                </Dropdown> :
                  <>
                    <ButtonPrimary
                      onClick={goToSignUp}
                      variant="outlined"
                      text={t('Join the Membership')}
                      className="btn-login-on-header me-2 ms-2"
                      style={{ minWidth: `${i18n.language === 'en' ? 84 : 74}px` }}
                    />
                    <ButtonPrimary
                      onClick={goToLogin}
                      variant="outlined"
                      text={t('Sign In')}
                      className="btn-login-on-header"
                      style={{ minWidth: `${i18n.language === 'en' ? 84 : 74}px` }}
                    />
                  </>}
              </ul>
            </div>
          </nav>
        </div>
        {path === "dashboard" || path === "index-2" ?
          <div className="page-titles">
            <div className="sub-dz-head">
              <div className="d-flex align-items-center dz-head-title">
                <h2 className="text-white m-0">{t('Service Introduction')}</h2>
                {/* <p className="ms-2 text-warning">Welcome Back Yatin Sharma!</p> */}
              </div>
            </div>
          </div>
          :
          <div className="page-titles mb-0 pt-3 pt-md-5  pb-0 pb-md-3">
            <div className="d-flex align-items-center">
              <h2 style={{ textTransform: "capitalize" }} className={`ms-2 ${['/', '/service-introduction']?.includes(`/${path}`) ? '' : 'title-page-small'}`} dangerouslySetInnerHTML={{ __html: nameMenuActive || <></> }}>
              </h2>
            </div>
            {path === "" && <ol className="breadcrumb">
              <li className="breadcrumb-item ms-2">
                {/* <Link to={"#"} style={{ textTransform: "capitalize" }}>{t(nameMenuActive || '')}</Link> */}
                <div className="text-end" style={{ fontSize: '0.875rem' }}>
                  {moment().subtract(2, 'days').format('MM월 DD일')} 기준 랭킹 데이터입니다.
                </div>
              </li>
            </ol>}
          </div>
        }
      </div>

    </>
  );
};

export default Header;

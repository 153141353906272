import { CATEGORY_TYPE } from 'helpers/constans';
import { formatNumberWithCommas } from 'helpers/format';
import React, { useEffect, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { Link } from 'react-router-dom';
import CustomSkeleton from 'ui/components/loadings/CustomSkeleton';
const createArray = () => {
    const obj = {
        id: 1,
        rank: 1,
        keyword: {
            keyword: 'Keyword'
        },
        index: 0.0,
        rank_diff: 0.0
    };
    const array = Array.from({ length: 10 }, (_, index) => ({
        ...obj,
        id: index + 1,
        rank: index + 1
    }));

    return array;
};

const dataDefault = {
    isLoading: true,
    category: {
        name: 'Category'
    },
    list: createArray()
};


const RecentTransaction = ({ data }) => {
    const [dataTable, setDataTable] = useState(dataDefault);
    const isDark = document.body.getAttribute('data-theme-version') === 'dark';
    useEffect(() => {
        if (data) {
            setDataTable((prev) => data);
        }
    }, [data]);
    const linkToRedirect = (category) => {
        const obj = {};
        if (category?.category_level_1?.id && category?.category_level_2?.id) {
            obj.category_level_1 = category?.category_level_2?.id;
            obj.category_level_2 = category?.category?.id;
        } else if (category?.category_level_1?.id) {
            obj.category_level_1 = category?.category?.id;
        }
        return `/${String((category?.category_level_1 || category?.category_level_2 || category?.category)?.type) === String(CATEGORY_TYPE.TREND_INDEX) ? 'people' : 'industry'}?category_id=${category?.category_level_1?.id || category?.category_level_2?.id || category?.category?.id}&${new URLSearchParams(obj).toString()}`;
    }
    return (
        <div className="card transaction-table">
            <div className="card-header border-0 flex-wrap pb-0">
                <div className="mb-2 mb-sm-3 d-flex text-center w-100 justify-content-between align-items-center">
                    <div style={{ width: "24px", height: "24px" }}>
                    </div>
                    <h4 className="text-center mb-0 title-table-ranking">
                        <CustomSkeleton isDark={isDark} isLoading={!!dataTable?.isLoading} height="1.6rem">
                            {dataTable?.category?.name || ''}
                        </CustomSkeleton>
                    </h4>
                    <div>
                        <Link to={linkToRedirect(dataTable)}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 20L17 12L9 4" stroke="var(--text-dark)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="card-body p-0">
                <div className="tab-content" id="myTabContent1">
                    <div className="tab-pane fade show active" id="Week" role="tabpanel" aria-labelledby="Week-tab">
                        <div className="table-responsive">
                            <table className="table table-responsive table-font-size" >
                                <tbody>
                                    {dataTable?.list?.map((item, ind) => (
                                        <tr key={item?.id}>
                                            <td style={{ width: "10%" }}>
                                                <CustomSkeleton isDark={isDark} isLoading={!!dataTable?.isLoading} height="1.25rem">{item?.rank} </CustomSkeleton>
                                            </td>
                                            <td style={{ width: "40%" }}>
                                                <CustomSkeleton isDark={isDark} isLoading={!!dataTable?.isLoading} height="1.25rem">{item?.keyword?.keyword || ''}</CustomSkeleton>
                                            </td>
                                            <td style={{ width: "30%" }} className="text-end">
                                                <CustomSkeleton isDark={isDark} isLoading={!!dataTable?.isLoading} height="1.25rem">
                                                    {formatNumberWithCommas(item?.index)}
                                                </CustomSkeleton>
                                            </td>
                                            <td style={{ width: "20%" }}>
                                                <CustomSkeleton isDark={isDark} isLoading={!!dataTable?.isLoading} height="1.25rem">
                                                    <div className="d-flex align-items-center">
                                                        {item?.is_new ? (<>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clip-path="url(#clip0_500_1816)">
                                                                    <circle cx="12" cy="12" r="12" fill="#F6B40C" />
                                                                    <path d="M8.64109 15H7.27309L4.98509 11.536V15H3.61709V9.384H4.98509L7.27309 12.864V9.384H8.64109V15ZM11.0007 10.48V11.616H12.8327V12.672H11.0007V13.904H13.0727V15H9.63272V9.384H13.0727V10.48H11.0007ZM21.6888 9.384L20.2248 15H18.5688L17.6728 11.304L16.7448 15H15.0888L13.6648 9.384H15.1288L15.9368 13.472L16.9368 9.384H18.4408L19.4008 13.472L20.2168 9.384H21.6888Z" fill="white" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_500_1816">
                                                                        <rect width="24" height="24" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>

                                                        </>) : (<>
                                                            {item?.rank_diff === 0 ? (
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clip-path="url(#clip0_500_1830)">
                                                                        <circle cx="12" cy="12" r="12" fill="#13B440" />
                                                                        <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_500_1830">
                                                                            <rect width="24" height="24" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>

                                                            ) : (
                                                                Number(item?.rank_diff) < 0 ? (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clip-path="url(#clip0_500_1802)">
                                                                        <circle cx="12" cy="12" r="12" fill="#3C8AFF" />
                                                                        <path d="M6 13.4L12 19M12 19L18 13.4M12 19L12 5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_500_1802">
                                                                            <rect width="24" height="24" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                                ) : (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clip-path="url(#clip0_500_1788)">
                                                                        <circle cx="12" cy="12" r="12" fill="#FD5353" />
                                                                        <path d="M18 10.6L12 5M12 5L6 10.6M12 5L12 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_500_1788">
                                                                            <rect width="24" height="24" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                                )
                                                            )}
                                                        </>)}
                                                        <div className="" style={{ minWidth: '40px' }}>{Number(item?.rank_past || 0) === 0 ? '-' : Math.abs(item?.rank_past)}</div>
                                                    </div>
                                                </CustomSkeleton>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(RecentTransaction);
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useRef } from 'react';
import { Tab } from 'react-bootstrap';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useToastNotifications from "ui/components/Hooks/useToastNotifications";

registerLocale("en", en);
registerLocale("ko", ko);

const createArray = () => {
    const obj = {
        isLoading: true,
    };
    const array = Array.from({ length: 10 }, (_, index) => ({
        ...obj,
        id: index + 1,
        rank: index + 1
    }));
    return array;
};

const DATA_KEYWORD_DEFAULT_LOADING = createArray();

const Search = () => {
    const { notify } = useToastNotifications();
    const { t, i18n } = useTranslation();
    const user = useSelector((state) => state?.Login);
    const { message } = useSelector((state) => state?.Message);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isFirstLoadingPageRef = useRef(true);
    // const { id } = useParams();

    return (
        <>
            <div className="row">
                <Tab.Container defaultActiveKey={'AAA'}>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header border-0 p-4"><h4 className="mb-0">{t('Developing')}</h4></div>
                        </div>
                    </div>
                </Tab.Container>
            </div>
        </>
    )
}
export default Search;
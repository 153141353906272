import rankingApi from "api/rankingApi";
import { formatQueryParams } from "helpers/format";
import { createAsyncThunk } from "@reduxjs/toolkit";
import masterApi from "api/masterApi";

export const allCategoryMenu = createAsyncThunk("Menu Category", async (params: any = {}) => {
  try {
    const response = await masterApi.allCategoryMenu(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getAllCategoriesNormal = async (params: any = {}) => {
  try {
    const response = await masterApi.allCategories(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllTicketsNormal = async (params: any = {}) => {
  try {
    const response = await masterApi.allTickets(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};
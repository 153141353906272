export const MenuListBottom = [

    {
        title: 'Service Introduction',
        classsChange: 'mm-collapse',
        iconStyle: <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 7.51221H22V17.6313C22 18.9469 21.745 20.2501 21.2491 21.4688C20.1463 24.1791 17.9307 26.2842 15.1671 27.2474L13.1848 27.9383C12.9522 28.0193 12.6993 28.0206 12.466 27.9419L10.5959 27.311C7.63328 26.3116 5.33455 23.945 4.42206 20.955C4.14224 20.0381 4 19.0847 4 18.1261V7.51221ZM6.21538 9.7271V18.1261C6.21538 18.8657 6.32512 19.6012 6.54101 20.3086C7.24501 22.6154 9.01851 24.4413 11.3042 25.2124L12.8143 25.7218L14.4379 25.1559C16.6003 24.4022 18.3341 22.755 19.1971 20.6341C19.5851 19.6805 19.7846 18.6608 19.7846 17.6313V9.7271H6.21538Z" fill="#1A1A1C" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84624 11.9512H13.9824C15.641 11.9512 16.9855 13.3056 16.9855 14.9765C16.9855 16.6473 15.641 18.0018 13.9824 18.0018H8.84624V11.9512ZM11.0916 14.2131V15.7399H13.9824C14.4009 15.7399 14.7402 15.3981 14.7402 14.9765C14.7402 14.5549 14.4009 14.2131 13.9824 14.2131H11.0916Z" fill="#1A1A1C" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84624 15.7402H12.102C14.8921 15.7402 17.1539 18.0188 17.1539 20.8295H14.9086C14.9086 19.268 13.652 18.0021 12.102 18.0021H11.0916V20.8295H8.84624V15.7402Z" fill="#1A1A1C" />
            <path d="M16.9293 1.84769V3.39273H14.4458L14.82 1.59283L13 0L11.2479 1.59283L11.6222 3.40866H9.1387V1.86361L6.07689 0.557491L6.62121 5.46341H13.034H19.4468L19.923 0.541563L16.9293 1.84769Z" fill="#F6B40C" />
        </svg>,
        iconStyleActive: <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 7.5127H22V17.6318C22 18.9474 21.745 20.2506 21.2491 21.4693C20.1463 24.1796 17.9307 26.2847 15.1671 27.2479L13.1848 27.9388C12.9522 28.0198 12.6993 28.0211 12.466 27.9424L10.5959 27.3115C7.63328 26.312 5.33455 23.9455 4.42206 20.9555C4.14224 20.0386 4 19.0852 4 18.1265V7.5127ZM6.21538 9.72759V18.1265C6.21538 18.8662 6.32512 19.6017 6.54101 20.3091C7.24501 22.6159 9.01851 24.4418 11.3042 25.2129L12.8143 25.7223L14.4379 25.1564C16.6003 24.4027 18.3341 22.7555 19.1971 20.6346C19.5851 19.681 19.7846 18.6613 19.7846 17.6318V9.72759H6.21538Z" fill="#FFF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84624 11.9512H13.9824C15.641 11.9512 16.9855 13.3056 16.9855 14.9765C16.9855 16.6473 15.641 18.0018 13.9824 18.0018H8.84624V11.9512ZM11.0916 14.2131V15.7399H13.9824C14.4009 15.7399 14.7402 15.3981 14.7402 14.9765C14.7402 14.5549 14.4009 14.2131 13.9824 14.2131H11.0916Z" fill="#FFF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84624 15.7402H12.102C14.8921 15.7402 17.1539 18.0188 17.1539 20.8295H14.9086C14.9086 19.268 13.652 18.0021 12.102 18.0021H11.0916V20.8295H8.84624V15.7402Z" fill="#FFF" />
            <path d="M16.9293 1.84769V3.39273H14.4458L14.82 1.59283L13 0L11.2479 1.59283L11.6222 3.40866H9.1387V1.86361L6.07689 0.557491L6.62121 5.46341H13.034H19.4468L19.923 0.541563L16.9293 1.84769Z" fill="#F6B40C" />
        </svg>,
        path: '/service-introduction',
        to: '/service-introduction'
    },
]

export const MenuList = [

    {
        title: 'Dashboard',
        classsChange: 'mm-collapse',
        iconStyle: <svg width="24" height="24" className="icon-item-menu-fill" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13 21C13 21.553 13.447 22 14 22H17C18.061 22 19.078 21.579 19.828 20.829C20.578 20.079 21 19.061 21 18C21 17.426 21 17 21 17C21 16.448 20.552 16 20 16H14C13.447 16 13 16.448 13 17V21ZM10 22C10.552 22 11 21.553 11 21V11C11 10.448 10.552 10 10 10H4C3.447 10 3 10.448 3 11V18C3 19.061 3.421 20.079 4.171 20.829C4.921 21.579 5.939 22 7 22H10ZM19 18H15V20H17C17.53 20 18.039 19.79 18.414 19.415C18.789 19.04 19 18.531 19 18ZM9 20V12H5V18C5 18.531 5.21 19.04 5.586 19.415C5.961 19.79 6.469 20 7 20H9ZM14 2C13.447 2 13 2.448 13 3V13C13 13.553 13.447 14 14 14H20C20.552 14 21 13.553 21 13V6C21 4.94 20.578 3.922 19.828 3.172C19.078 2.422 18.061 2 17 2H14ZM15 4V12H19V6C19 5.47 18.789 4.961 18.414 4.586C18.039 4.211 17.53 4 17 4H15ZM11 3C11 2.448 10.552 2 10 2H7C5.939 2 4.921 2.422 4.171 3.172C3.421 3.922 3 4.94 3 6V7C3 7.553 3.447 8 4 8H10C10.552 8 11 7.553 11 7V3ZM9 4H7C6.469 4 5.961 4.211 5.586 4.586C5.21 4.961 5 5.47 5 6H9V4Z" fill="#6A6970" />
        </svg>,
        path: '/',
        to: '/'
    },


    //Dashboard
    {
        title: '플레이스 분석',
        classsChange: 'mm-collapse',
        iconStyle: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="icon-item-menu-stroke-rect" viewBox="0 0 24 24" fill="none">
            <path d="M3 21V17M9 21V13M15 21V15M21 21V11M8.43934 5.56066C8.71079 5.83211 9.08579 6 9.5 6C9.91421 6 10.2892 5.83211 10.5607 5.56066M8.43934 5.56066C8.16789 5.28921 8 4.91421 8 4.5C8 3.67157 8.67157 3 9.5 3C10.3284 3 11 3.67157 11 4.5C11 4.91421 10.8321 5.28921 10.5607 5.56066M8.43934 5.56066L5.56066 8.43934M5.56066 8.43934C5.28921 8.16789 4.91421 8 4.5 8C3.67157 8 3 8.67157 3 9.5C3 10.3284 3.67157 11 4.5 11C5.32843 11 6 10.3284 6 9.5C6 9.08579 5.83211 8.71079 5.56066 8.43934ZM10.5607 5.56066L13.4393 8.43934M13.4393 8.43934C13.1679 8.71079 13 9.08579 13 9.5C13 10.3284 13.6716 11 14.5 11C15.3284 11 16 10.3284 16 9.5C16 9.08579 15.8321 8.71079 15.5607 8.43934M13.4393 8.43934C13.7108 8.16789 14.0858 8 14.5 8C14.9142 8 15.2892 8.16789 15.5607 8.43934M15.5607 8.43934L18.4393 5.56066M18.4393 5.56066C18.7108 5.83211 19.0858 6 19.5 6C20.3284 6 21 5.32843 21 4.5C21 3.67157 20.3284 3 19.5 3C18.6716 3 18 3.67157 18 4.5C18 4.91421 18.1679 5.28921 18.4393 5.56066Z" stroke="#6A6970" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>,
        // path: '/personal',
        // to: '/personal',
        content: [
            {
                parent: {
                    title: '플레이스 분석'
                },
                title: 'People',
                path: '/people',
                to: '/people'
            },
            {
                parent: {
                    title: '플레이스 분석'
                },
                title: 'Industry',
                path: '/industry',
                to: '/industry'
            },
        ],
    },

    // {
    //     title: 'Trading',	
    //     classsChange: 'mm-collapse',		
    //     iconStyle: <i className="material-symbols-outlined">monitoring</i>,
    //     content: [
    //         {
    //             title: 'Market',
    //             to: 'trading-market',					
    //         },
    //         {
    //             title: 'Ico Listing',
    //             to: 'ico-listing',
    //         },
    //         {
    //             title: 'P2P',
    //             to: 'p2p',
    //         },
    //         {
    //             title: 'Future',
    //             to: 'future',
    //         },

    //     ],
    // },
    // {
    //     title: 'Crypto',	
    //     classsChange: 'mm-collapse',		
    //     iconStyle: <i className="material-symbols-outlined">monetization_on</i>,
    //     content: [
    //         {
    //             title: 'Market Watch',
    //             to: 'market-watch',					
    //         },
    //         {
    //             title: 'Exchange',
    //             to: 'exchange',					
    //         },
    //         {
    //             title: 'Banking',
    //             to: 'banking',					
    //         },
    //     ]
    // },
    // {
    //     title: 'Reports',	
    //     classsChange: 'mm-collapse',		
    //     iconStyle: <i className="material-symbols-outlined">lab_profile</i>,
    //     content: [
    //         {
    //             title: 'History',
    //             to: 'history',					
    //         },
    //         {
    //             title: 'Orders',
    //             to: 'orders',					
    //         },
    //         {
    //             title: 'Report',
    //             to: 'reports',					
    //         },
    //         {
    //             title: 'User',
    //             to: 'user',					
    //         },
    //     ]
    // },

    // //Apps
    // // {
    // //     title:"Apps",
    // //     classsChange: "menu-title"
    // // },
    // {
    //     title: 'Apps',	
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="material-symbols-outlined">apps_outage</i>,
    //     content: [
    //         {
    //             title: 'Profile',
    //             to: 'app-profile'
    //         },
    //         {
    //             title: 'Edit Profile',
    //             to: 'edit-profile'
    //         },
    //         {
    //             title: 'Post Details',
    //             to: 'post-details'
    //         },
    //         {
    //             title: 'Email',                
    //             hasMenu : true,
    //             content: [
    //                 {
    //                     title: 'Compose',
    //                     to: 'email-compose',
    //                 },
    //                 {
    //                     title: 'Inbox',
    //                     to: 'email-inbox',
    //                 },
    //                 {
    //                     title: 'Read',
    //                     to: 'email-read',
    //                 }
    //             ],
    //         },
    //         {
    //             title:'Calendar',
    //             to: 'app-calender'
    //         },
    //         {
    //             title: 'Shop',
    //             //to: './',
    //             hasMenu : true,
    //             content: [
    //                 {
    //                     title: 'Product Grid',
    //                     to: 'ecom-product-grid',
    //                 },
    //                 {
    //                     title: 'Product List',
    //                     to: 'ecom-product-list',
    //                 },
    //                 {
    //                     title: 'Product Details',
    //                     to: 'ecom-product-detail',
    //                 },
    //                 {
    //                     title: 'Order',
    //                     to: 'ecom-product-order',
    //                 },
    //                 {
    //                     title: 'Checkout',
    //                     to: 'ecom-checkout',
    //                 },
    //                 {
    //                     title: 'Invoice',
    //                     to: 'ecom-invoice',
    //                 },
    //                 {
    //                     title: 'Customers',
    //                     to: 'ecom-customers',
    //                 },
    //             ],
    //         },
    //     ],
    // },

    // //CMS
    // {
    //     title : "CMS",
    //     classsChange: 'mm-collapse',
    //     // update:"New",
    //     iconStyle: <i className="flaticon-menu-1" />,
    //     content:[
    //         {
    //             title:'Content',
    //             to:'/content'
    //         },
    //         {
    //             title:'Menu',
    //             to:'/menu'
    //         },
    //         {
    //             title:'Email Template',
    //             to:'/email-template'
    //         },
    //         {
    //             title:'Blog',
    //             to:'/blog'
    //         },
    //         {
    //             title:'Add Content',
    //             to:'/content-add'
    //         },
    //         {
    //             title:'Add Email',
    //             to:'/add-email'
    //         },
    //         {
    //             title:'Add Blog',
    //             to:'/add-blog'
    //         },
    //         {
    //             title:'Blog Category',
    //             to:'/blog-category'
    //         },
    //     ],
    // },
    // //Charts
    // {
    //     title: 'Charts',	
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="material-symbols-outlined">donut_large</i>,
    //     content: [

    //         {
    //             title: 'RechartJs',
    //             to: 'chart-rechart',					
    //         },
    //         {
    //             title: 'Chartjs',
    //             to: 'chart-chartjs',					
    //         },
    //         {
    //             title: 'Sparkline',
    //             to: 'chart-sparkline',					
    //         },
    //         {
    //             title: 'Apexchart',
    //             to: 'chart-apexchart',					
    //         },
    //     ]
    // },


    // //Boosttrap
    // {
    //     title: 'Bootstrap',	
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="material-symbols-outlined">favorite</i>,	
    //     content: [
    //         {
    //             title: 'Accordion',
    //             to: 'ui-accordion',					
    //         },
    //         {
    //             title: 'Alert',
    //             to: 'ui-alert',					
    //         },
    //         {
    //             title: 'Badge',
    //             to: 'ui-badge',					
    //         },
    //         {
    //             title: 'Button',
    //             to: 'ui-button',					
    //         },
    //         {
    //             title: 'Modal',
    //             to: 'ui-modal',					
    //         },
    //         {
    //             title: 'Button Group',
    //             to: 'ui-button-group',					
    //         },
    //         {
    //             title: 'List Group',
    //             to: 'ui-list-group',					
    //         },
    //         {
    //             title: 'Cards',
    //             to: 'ui-card',					
    //         },
    //         {
    //             title: 'Carousel',
    //             to: 'ui-carousel',					
    //         },
    //         {
    //             title: 'Dropdown',
    //             to: 'ui-dropdown',					
    //         },
    //         {
    //             title: 'Popover',
    //             to: 'ui-popover',					
    //         },
    //         {
    //             title: 'Progressbar',
    //             to: 'ui-progressbar',					
    //         },
    //         {
    //             title: 'Tab',
    //             to: 'ui-tab',					
    //         },
    //         {
    //             title: 'Typography',
    //             to: 'ui-typography',					
    //         },
    //         {
    //             title: 'Pagination',
    //             to: 'ui-pagination',					
    //         },
    //         {
    //             title: 'Grid',
    //             to: 'ui-grid',					
    //         },
    //     ]
    // },
    // //plugins
    // {
    //     title:'Plugins',
    //     classsChange: 'mm-collapse',
    //     iconStyle : <i className="material-symbols-outlined">scatter_plot</i>,
    //     content : [
    //         {
    //             title:'Select 2',
    //             to: 'uc-select2',
    //         },

    //         {
    //             title:'Sweet Alert',
    //             to: 'uc-sweetalert',
    //         },
    //         {
    //             title:'Toastr',
    //             to: 'uc-toastr',
    //         },            
    //         {
    //             title:'Light Gallery',
    //             to: 'uc-lightgallery',
    //         },
    //     ]
    // },
    // //Widget
    // {   
    //     title:'Widget',
    //     iconStyle: <i className="material-symbols-outlined">widgets</i>,
    //     classsChange: 'mm-collapse',        
    //     content : [
    //         {
    //             title:'Widget Card',
    //             to:'widget-card'
    //         },
    //         {
    //             title:'Widget Chart',
    //             to:'widget-chart'
    //         },
    //         {
    //             title:'Widget List',
    //             to:'widget-list'
    //         },
    //     ]
    // },


    // //Forms
    // {
    //     title:'Forms',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="material-symbols-outlined">request_quote</i>,
    //     content : [
    //         {
    //             title:'Form Elements',
    //             to: 'form-element',
    //         },
    //         {
    //             title:'Wizard',
    //             to: 'form-wizard',
    //         },
    //         {
    //             title:'CkEditor',
    //             to: 'form-ckeditor',
    //         },
    //         {
    //             title:'Pickers',
    //             to: 'form-pickers',
    //         },
    //         {
    //             title:'Form Validate',
    //             to: 'form-validation',
    //         },

    //     ]
    // },


    // {
    //     title:'Table',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="material-symbols-outlined">table_chart</i>,
    //     content : [
    //         {
    //             title:'Table Filtering',
    //             to: 'table-filtering',
    //         },
    //         {
    //             title:'Table Sorting',
    //             to: 'table-sorting',
    //         },
    //         {
    //             title:'Bootstrap',
    //             to: 'table-bootstrap-basic',
    //         },


    //     ]
    // },
    // //Pages
    // //Table
    // // {
    // //     title:"Extra",
    // //     classsChange:"menu-title"
    // // },
    // {
    //     title:'Pages',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="material-symbols-outlined">lab_profile</i>,
    //     content : [
    //         {
    //             title:'Error',
    //             hasMenu : true,
    //             content : [
    //                 {
    //                     title: 'Error 400',
    //                     to : 'page-error-400',
    //                 },
    //                 {
    //                     title: 'Error 403',
    //                     to : 'page-error-403',
    //                 },
    //                 {
    //                     title: 'Error 404',
    //                     to : 'page-error-404',
    //                 },
    //                 {
    //                     title: 'Error 500',
    //                     to : 'page-error-500',
    //                 },
    //                 {
    //                     title: 'Error 503',
    //                     to : 'page-error-503',
    //                 },
    //             ],
    //         },
    //         {
    //             title:'Lock Screen',
    //             to: 'page-lock-screen',
    //         },
    //         {
    //             title:'Empty Page',
    //             to: 'empty-page',
    //         },

    //     ]
    // },

]
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { CATEGORY_TYPE, FREQUENCY_TYPE, PRODUCT_TYPE, TYPE_MESSAGES } from 'helpers/constans';
import { formatNumberWithCommas } from "helpers/format";
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Container, Modal, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { getAllCategoriesNormal, getAllTicketsNormal, getRankingKeywordsByNormal } from 'store/thunks';
import { getTicket, postPaymentProcess, postVote } from "store/voting/thunk";
import ButtonDanger from "ui/components/buttons/ButtonDanger";
import ButtonPrimary from "ui/components/buttons/ButtonPrimary";
import ButtonSecondary from "ui/components/buttons/ButtonSecondary";
import ButtonWarning from "ui/components/buttons/ButtonWarning";
import RadioDanger from "ui/components/buttons/RadioDanger";
import RadioPrimary from "ui/components/buttons/RadioPrimary";
import useToastNotifications from "ui/components/Hooks/useToastNotifications";
import RangeDatePicker from "ui/components/pickers/RangeDatePicker";
import BasicTabs from "ui/components/tab/BasicTabs";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import PaymentNotification from "../payment/components/PaymentNotification";
import TableContainer from "./Table";
import Loading from "./Table/Loading";

const SORT_BY_DEFAULT = 'index';
const ORDER_BY_DEFAULT = 'DESC';
const LIMIT_PER_PAGE = 30;
registerLocale("en", en);
registerLocale("ko", ko);

const createArray = () => {
    const obj = {
        isLoading: true,
    };
    const array = Array.from({ length: 10 }, (_, index) => ({
        ...obj,
        id: index + 1,
        rank: index + 1
    }));
    return array;
};

const DATA_KEYWORD_DEFAULT_LOADING = createArray();

const Category = ({ categoryType }) => {
    const { notify } = useToastNotifications();
    const { t, i18n } = useTranslation();
    const user = useSelector((state) => state?.Login);
    const { message } = useSelector((state) => state?.Message);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isFirstLoadingPageRef = useRef(true);
    // const { id } = useParams();
    const paymentCurrentRef = useRef('');

    const INTERVAL_TYPE = [{ label: t('Daily'), value: 1 }, { label: t('Weekly'), value: 2 }, { label: t('Monthly'), value: 3 }];

    const INTERVAL_TIME = {
        [`${INTERVAL_TYPE[0].value}`]: [{ label: t('2 Days Ago'), value: 'D_2' }, { label: t('3 Days Ago'), value: 'D_3' }, { label: t('7 Days Ago'), value: 'D_7' }, { label: t('Select Date'), value: 'SE' }],
        [`${INTERVAL_TYPE[1].value}`]: [{ label: t('Last Week'), value: 'W_1' }, { label: t('2 Weeks Ago'), value: 'W_2' }, { label: t('3 Weeks Ago'), value: 'W_3' }, { label: t('Select Date'), value: 'SE' }],
        [`${INTERVAL_TYPE[2].value}`]: [{ label: t('Last Month'), value: 'M_1' }, { label: t('2 Months Ago'), value: 'M_2' }, { label: t('3 Months Ago'), value: 'M_3' }, { label: t('Select Date'), value: 'SE' }],
    };

    const [query, setQuery] = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, LIMIT_PER_PAGE),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        keyword: withDefault(StringParam, ""),
        sub_category_id: withDefault(StringParam, ""),
        date: withDefault(StringParam, moment().subtract(2, "days").format("Y-MM-DD")),
        frequency_type: withDefault(StringParam, INTERVAL_TYPE[0]?.value),
        interval_time: withDefault(StringParam, INTERVAL_TIME[`${INTERVAL_TYPE[0]?.value}`][0]?.value),
        category_id: withDefault(StringParam, ''),
        category_level_1: withDefault(StringParam, ''),
        category_level_2: withDefault(StringParam, ''),
        is_clear: withDefault(StringParam, '0'),
    });

    const [isLoadMore, setIsLoadMore] = useState(true);

    const [keywordVote, setKeywordVote] = useState(null);
    const [currentTicket, setCurrentTicket] = useState(null);
    const [ticketAmountVote, setTicketAmountVote] = useState(0);
    const [keywordVotedInfo, setKeywordVotedInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [confirmLogin, setConfirmLogin] = useState(false);
    const [chargeInfo, setChargeInfo] = useState(null);

    const [tickets, setTickets] = useState([]);

    const [categories, setCategories] = useState([]);

    const [categoriesLevel3, setCategoriesLevel3] = useState(null);

    const [optionCategoryDropdown, setOptionCategoryDropdown] = useState({});
    const [categoryDropdown, setCategoryDropdown] = useState((query?.category_level_1 && query?.category_level_2) ? {
        level_1: {
            value: query?.category_level_1,
            label: ''
        },
        level_2: {
            value: query?.category_level_2,
            label: ''
        }
    } : (query?.category_level_1 ? {
        level_1: {
            value: query?.category_level_2,
            label: ''
        }
    } : {}));

    const [keywords, setKeywords] = useState({ list: [], total: 0 });

    const [isKeywordLoading, setIsKeywordLoading] = useState(false);

    const [keywordSearch, setKeywordSearch] = useState(query.keyword || '');

    const [dateSearch, setDateSearch] = useState(query?.date || moment().subtract(2, "days").format('Y-MM-DD'));

    const [frequencyTypeSearch, setFrequencyTypeSearch] = useState(INTERVAL_TYPE.find((item) => String(item?.value) === String(query?.frequency_type)) || INTERVAL_TYPE[0]);

    const [intervalTimeSearch, setIntervalTimeSearch] = useState(INTERVAL_TIME[`${frequencyTypeSearch?.value}`].find((item) => String(item?.value) === String(query?.interval_time))?.value || INTERVAL_TIME[`${frequencyTypeSearch?.value}`][0]?.value);

    const getDateRange = (type_picker, date_string) => {
        const date = moment(date_string);
        if (String(type_picker) === String(FREQUENCY_TYPE.WEEKLY)) {
            const startDateOfWeek = date.clone().startOf('isoWeek');
            const endDateOfWeek = date.clone().endOf('isoWeek');
            return [startDateOfWeek.format('YYYY-MM-DD'), endDateOfWeek.format('YYYY-MM-DD')];
        } else if (String(type_picker) === String(FREQUENCY_TYPE.MONTHLY)) {
            const startDateOfMonth = date.clone().startOf('month');
            const endDateOfMonth = date.clone().endOf('month');
            return [startDateOfMonth.format('YYYY-MM-DD'), endDateOfMonth.format('YYYY-MM-DD')];
        } else {
            return [date_string, date_string];
        }
    }
    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            keyword: keywordSearch || '',
            date: getDateRange(frequencyTypeSearch?.value, dateSearch)[0],
            sub_category_id: categoryDropdown?.level_2?.value || categoryDropdown?.level_1?.value || '',
            category_level_1: categoryDropdown?.level_1?.value || '',
            category_level_2: categoryDropdown?.level_2?.value || '',
            frequency_type: frequencyTypeSearch?.value || INTERVAL_TYPE[0]?.value,
            interval_time: intervalTimeSearch,
            time_request: + new Date(),
            is_clear: '0'
        });
    };

    const resetData = (categoryIdChange = '') => {
        const queryNew = {
            ...query,
            keyword: '',
            date: moment().subtract(2, "days").format("Y-MM-DD"),
            ...(!!categoryIdChange ? { category_id: categoryIdChange } : {}),
            sub_category_id: '',
            category_level_1: '',
            category_level_2: '',
            frequency_type: INTERVAL_TYPE[0]?.value,
            interval_time: INTERVAL_TIME[`${INTERVAL_TYPE[0]?.value}`][0]?.value,
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        };
        setQuery(queryNew, "push");
        setDateSearch(moment().subtract(2, "days").format("Y-MM-DD"));
        setKeywordSearch("");
        setCategoryDropdown({});
        setFrequencyTypeSearch(INTERVAL_TYPE[0]);
        setIntervalTimeSearch(INTERVAL_TIME[`${INTERVAL_TYPE[0]?.value}`][0]?.value);
    };

    const handleQueryData = async () => {
        try {
            if (isFirstLoadingPageRef.current && Number(query?.page) !== 1) {
                resetData();
                return;
            };
            isFirstLoadingPageRef.current = false;
            setIsKeywordLoading((prev) => true);
            if (Number(query?.page) === 1) {
                setKeywords((_prev) => ({
                    total: 0,
                    list: DATA_KEYWORD_DEFAULT_LOADING
                }));
            }
            const res = await getRankingKeywordsByNormal(query);
            setIsLoadMore((_prev) => res?.data?.list?.length === LIMIT_PER_PAGE);
            setKeywords((prev) => ({
                list: Number(query?.page) === 1 ? (res?.data?.list || []) : (prev?.list || []).concat(res?.data?.list || []),
                total: res?.data?.pagination?.total || 0,
            }));
            setIsKeywordLoading((prev) => false);
        } catch (error) {
            setIsKeywordLoading((prev) => false);
        }
    };

    const handleChangeSorting = (sortBy) => {
        setQuery({ ...query, ...sortBy, page: 1 });
    };

    const handleLoadMore = () => {
        setQuery({
            ...query,
            page: query?.page + 1
        });
    }

    const handleChangePicker = (value, type) => {
        setDateSearch((_prev) => value ? moment(value).format("Y-MM-DD") : '');
    }
    const handleChangeDatePicker = (date) => {
        const frequencyValue = String(frequencyTypeSearch?.value);
        switch (frequencyValue) {
            case String(FREQUENCY_TYPE.WEEKLY):
                handleChangePicker(moment(date).startOf('isoWeek').toDate());
                break;
            case String(FREQUENCY_TYPE.MONTHLY):
            case String(FREQUENCY_TYPE.DAILY):
                handleChangePicker(date);
                break;
            default:
                throw new Error('Invalid frequency type');
        }
    }

    const handleChangeIntervalTime = (value) => {
        setIntervalTimeSearch((_prev) => value);
    }

    const goToLogin = () => {
        const currentUrl = window.location.pathname + window.location.search; // Get the current URL
        localStorage.setItem('referralUrl', currentUrl);
        navigate('/login');
    };

    useEffect(() => {
        const mapping = {
            'D_2': moment().subtract(2, 'days').startOf('day').toDate(),
            'D_3': moment().subtract(3, 'days').toDate(),
            'D_7': moment().subtract(7, 'days').toDate(),
            'W_1': moment().subtract(1, 'weeks').startOf('isoWeek').toDate(),
            'W_2': moment().subtract(2, 'weeks').startOf('isoWeek').toDate(),
            'W_3': moment().subtract(3, 'weeks').startOf('isoWeek').toDate(),
            'M_1': moment().subtract(1, 'months').startOf('month').toDate(),
            'M_2': moment().subtract(2, 'months').startOf('month').toDate(),
            'M_3': moment().subtract(3, 'months').startOf('month').toDate(),
        };

        if (mapping.hasOwnProperty(intervalTimeSearch)) {
            handleChangePicker(mapping[intervalTimeSearch], 'SELECT_OPTION');
        }

    }, [intervalTimeSearch]);

    const handleSetKeywordVote = (row) => {
        if (!user?.user?.id) {
            setConfirmLogin(true);
            return;
        }
        setKeywordVote(row);
    };

    const handleGetTicket = async () => {
        try {
            const [res] = await Promise.all([getTicket()]);
            setCurrentTicket(() => res?.data || {});
        } catch (error) {
            return error;
        }
    };

    const triggerRefresh = async () => {
        try {
            // resetData();
            setKeywordVote((prev) => null);
            setTicketAmountVote((prev) => 0);
            handleGetTicket();
        } catch (error) {
            return error;
        }
    }

    const handleVote = async () => {
        try {
            if (isLoading) {
                return;
            }
            if (Number(ticketAmountVote) > Number(currentTicket?.ticket_amount)) {
                notify('error', t('All your votes are waiting to be used. If you would like to vote additionally, please recharge your voting ticket.'));
                return;
            }
            setIsLoading((_prev) => true);
            const data = {
                category_id: Number(keywordVote?.category_id || ''),
                keyword_id: Number(keywordVote?.keyword_id || ''),
                ticket_amount: Number(ticketAmountVote),
            };
            const response = await postVote(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                setKeywordVotedInfo((_prev) => response?.data);
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                notify('error', `${response}`);
            }
        } catch (error) {
            setIsLoading((_prev) => false);
            notify('error', `${error?.message || ""}`);
            return error;
        }
    }

    const handleCloseVote = () => {
        if (!isLoading) {
            setKeywordVote((_prev) => null);
            setTicketAmountVote((_prev) => 0);
        }
    }

    const handlePollVote = (num) => {
        if (Number(ticketAmountVote) + Number(num) > Number(currentTicket?.ticket_amount)) {
            notify('error', t('Your current points are insufficient to perform this action!'));
            return;
        }
        setTicketAmountVote((prev) => {
            if (num === 'all') {
                return currentTicket?.ticket_amount;
            }
            return (Number(prev) + Number(num));
        });
    }

    const handlePurchase = async () => {
        try {
            if (isLoading || !chargeInfo?.ticket_id) {
                return;
            }
            setIsLoading((_prev) => true);
            const { protocol, hostname } = window.location;
            const domain = `${protocol}//${hostname}`;
            // const client_id = localStorage.getItem('client_id');
            const data = {
                // client_id,
                product_type: PRODUCT_TYPE.TICKET,
                product_id: chargeInfo?.ticket_id,
                useragent: window.innerWidth < 600 ? 'WM' : 'WP', // WP | WM
                return_url: `${domain}/payment/success`,
                cancel_url: `${domain}/payment/error`
            };
            const response = await postPaymentProcess(data);
            setIsLoading((_prev) => false);
            if (response?.code === 200) {
                paymentCurrentRef.current = response?.data?.payment_id;
                const win = window.open('about:blank', '_blank');
                win?.document
                    ?.write(`<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
                <html xmlns="http://www.w3.org/1999/xhtml" >
                <head>
                <meta http-equiv="Content-Type" content="text/html; charset=euc-kr">
                <link href="./css/style.css" type="text/css" rel="stylesheet"  media="all" />
                <title>*** �ٳ� �ſ�ī�� ���� ***</title>
                </head>
                <body>
                    <form name="form" ACTION=${response?.data?.start_url} METHOD="POST" >
                    <input TYPE="HIDDEN" NAME="STARTPARAMS" VALUE="${response?.data?.start_params}">
                    </form>
                    <script>
                        document.form.submit();
                    </script>
                </body>`);
            } else {
                setIsLoading((_prev) => false);
                notify('error', `${response}`);
            }
        } catch (error) {
            setIsLoading((_prev) => false);
            notify('error', `${error?.message || ""}`);
            return error;
        }
    }

    const handleCloseChargeInfo = () => {
        if (!isLoading) {
            setChargeInfo((_prev) => null);
        }
    }

    const handleShowMore = (cateArr) => {
        setCategoriesLevel3(cateArr);
    }
    // useEffect(() => {
    //     const intervalTimeMap = {
    //         [`${FREQUENCY_TYPE.DAILY}`]: INTERVAL_TIME[`${FREQUENCY_TYPE.DAILY}`][0], // Daily
    //         [`${FREQUENCY_TYPE.WEEKLY}`]: INTERVAL_TIME[`${FREQUENCY_TYPE.WEEKLY}`][0], // Weekly
    //         [`${FREQUENCY_TYPE.MONTHLY}`]: INTERVAL_TIME[`${FREQUENCY_TYPE.MONTHLY}`][0]  // Monthly
    //     };

    //     if (intervalTimeMap.hasOwnProperty(`${frequencyTypeSearch?.value}`)) {
    //         setIntervalTimeSearch(intervalTimeMap[`${frequencyTypeSearch?.value}`]?.value);
    //     } else {
    //         throw new Error('Invalid interval type');
    //     }

    // }, [frequencyTypeSearch]);

    useEffect(() => {
        if (!!query?.category_id) {
            handleQueryData();
        }
    }, [JSON.stringify(query)]);

    useEffect(() => {
        try {
            const cate = query?.category_id ? (categories || [])?.find((item) => String(item?.category_id) === String(query?.category_id)) : (categories || [])[0];
            if (categoryType && cate && String(cate?.type) !== String(categoryType)) {
                resetData(tabs[0]?.value);
                return;
            }
            setOptionCategoryDropdown((_prev) => cate);
            if (cate?.category_id && !query?.category_id) {
                setQuery({ ...query, category_id: cate?.category_id, page: 1 }, "push");
                return;
            }
            if (query?.category_level_2 || query?.category_level_1) {
                if (!!query?.category_level_2 && !!query?.category_level_1) {
                    const cate1 = (cate || [])?.subs?.find((item) => String(item?.category_id) === String(query?.category_level_1));
                    const cate2 = (cate1 || [])?.subs?.find((item) => String(item?.category_id) === String(query?.category_level_2));
                    setCategoryDropdown((_prev) => ({
                        level_1: { label: cate1?.name, value: cate1?.category_id },
                        level_2: { label: cate2?.name, value: cate2?.category_id },
                    }));
                } else if (!!query?.category_level_1) {
                    const cate1 = (cate || [])?.subs?.find((item) => String(item?.category_id) === String(query?.category_level_1));
                    setCategoryDropdown((_prev) => ({
                        level_1: { label: cate1?.name, value: cate1?.category_id },
                    }));
                } else {
                    setCategoryDropdown((_prev) => ({}));
                }
            };
        } catch (error) {
            return error;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query?.category_id, JSON.stringify(categories), categoryType]);


    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                const [res1, res2] = await Promise.all([getAllCategoriesNormal({}), getAllTicketsNormal()]);
                setCategories(() => res1?.data || []);
                setTickets(() => res2?.data || []);
            } catch (error) {
                return error;
            }
        };
        handleCallAllOption();
    }, []);

    useEffect(() => {
        if (user?.user?.id) {
            handleGetTicket();
        }
    }, [user?.user]);


    const isShowVote = useMemo((_prev) => {
        if (!!query?.sub_category_id && String(categoryDropdown?.level_2?.value) === String(query?.sub_category_id)) {
            return !!optionCategoryDropdown?.subs?.find((item) => String(item?.category_id) === String(categoryDropdown?.level_1?.value))?.subs?.find((item) => String(item?.category_id) === String(categoryDropdown?.level_2?.value))?.category_survey;
        }
        if (!!query?.sub_category_id && String(categoryDropdown?.level_1?.value) === String(query?.sub_category_id)) {
            return !!optionCategoryDropdown?.subs?.find((item) => String(item?.category_id) === String(categoryDropdown?.level_1?.value))?.category_survey;
        }
        if (!query?.sub_category_id) {
            return !!optionCategoryDropdown?.category_survey;
        }
        return false;
    }, [query?.category_id, query?.sub_category_id, JSON.stringify(categoryDropdown), JSON.stringify(optionCategoryDropdown)])

    useEffect(() => {
        if (frequencyTypeSearch) {
            setFrequencyTypeSearch((prev) => INTERVAL_TYPE.find((item) => item.value === prev?.value) || INTERVAL_TYPE[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    // useEffect(() => {
    //     if (message?.type === TYPE_MESSAGES.PAYMENT_SUCCESS) {
    //         setIsLoading((prev) => false);
    //         // notify('success', t('Transaction successful'));
    //         setChargeInfo((_prev) => ({ status: 'SUCCESS' }));
    //         handleGetTicket();
    //         dispatch(saveMessage(null));
    //     }
    //     if ([TYPE_MESSAGES.PAYMENT_CANCEL, TYPE_MESSAGES.PAYMENT_ERROR]?.includes(message?.type)) {
    //         setIsLoading((prev) => false);
    //         // notify('error', t('Transaction failed'));
    //         setChargeInfo((_prev) => ({ status: 'ERROR' }));
    //         dispatch(saveMessage(null));
    //     }
    // }, [message]);

    useEffect(() => {
        const channel = new BroadcastChannel('my_channel_payment');
        channel.onmessage = (event) => {
            const payment_id = paymentCurrentRef.current;
            if (event.data.type === TYPE_MESSAGES.PAYMENT_SUCCESS) {
                setIsLoading((prev) => false);
                // notify('success', t('Transaction successful'));
                handleGetTicket();
                if (String(payment_id) === String(event.data.payment_id)) {
                    setChargeInfo((_prev) => ({ status: 'SUCCESS' }));
                }
                return;
            }
            if ([TYPE_MESSAGES.PAYMENT_CANCEL, TYPE_MESSAGES.PAYMENT_ERROR]?.includes(event.data.type) && String(payment_id) === String(event.data.payment_id)) {
                setIsLoading((prev) => false);
                // notify('error', t('Transaction failed'));
                setChargeInfo((_prev) => ({ status: 'ERROR' }));
                return;
            }
        };
        return () => {
            channel.close();
        };
    }, []);

    const tabs = useMemo(() => {
        const cates = (categories || [])?.filter((item) => String(item?.type) === String(categoryType));
        const m = (cates || [])?.map((item) => ({
            text: String(item?.type) === String(CATEGORY_TYPE.STOCK) ? t('Corporation Rankify') : `${item?.name || ''} ${t('Rankify_KR')}`,
            value: item?.category_id
        }));
        return m;
    }, [JSON.stringify(categories), categoryType, i18n?.language]);

    const handleChangeTab = (cate) => {
        resetData(cate?.value);
        // navigate(`/personal?category_id=${cate?.value}`);
    }
    return (
        <>
            <div className="row">
                <Tab.Container defaultActiveKey={'AAA'}>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header border-0"><h4 className="mb-0" style={{ fontSize: '16px', fontWeight: 900, letterSpacing: '-.4px' }}>{t('Filter')}</h4></div>
                            <div className="card-body pt-0">
                                <div className="row filter-row">
                                    <div className="col-md-12 col-lg-12 col-xl-9">
                                        <div className="row mb-0 mb-lg-3">
                                            <div className="col-lg-4 col-sm-4">
                                                <label className='form-label mb-1'>{t('Keyword')}</label>
                                                <input type="text" className="form-control form-control-sm form-height-38 mb-xl-0 mb-3 fs-14" id="exampleFormControlInput1" placeholder={t('Keyword')}
                                                    value={keywordSearch}
                                                    onChange={(e) => setKeywordSearch(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            searchData();
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-4 col-sm-4 mb-xl-0 mb-3">
                                                <label className='form-label mb-1'>{t('Category')}</label>
                                                <Select
                                                    isSearchable={false}
                                                    placeholder={t('Category')}
                                                    value={categoryDropdown?.level_1 || null}
                                                    options={optionCategoryDropdown?.subs?.map((item) => ({ value: item?.category_id, label: item?.name }))}
                                                    // defaultValue={null}
                                                    className="custom-react-select custom-react-select-v2 form-height-38 "
                                                    noOptionsMessage={() => (<div>{t('No Options')}</div>)}
                                                    onChange={(item) => setCategoryDropdown({ level_1: item })}
                                                />
                                            </div>
                                            <div className="col-lg-4 col-sm-4 mb-xl-0 mb-3">
                                                <label className='form-label mb-1'>{t('Subcategory')}</label>
                                                <Select
                                                    placeholder={t('Subcategory')}
                                                    isSearchable={false}
                                                    value={categoryDropdown?.level_2 || null}
                                                    options={(optionCategoryDropdown?.subs?.find((item) => String(item?.category_id) === String(categoryDropdown?.level_1?.value))?.subs || [])?.map((item) => ({ value: item?.category_id, label: item?.name }))}
                                                    // defaultValue={options[0]}
                                                    onChange={(item) => setCategoryDropdown((prev) => ({ ...prev, level_2: item }))}
                                                    noOptionsMessage={() => (<div>{t('No Options')}</div>)}
                                                    className="custom-react-select custom-react-select-v2 form-height-38"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-3 col-sm-4 mb-xl-0 mb-3">
                                                <label className='form-label mb-1'>{t('Interval')}</label>
                                                <div className="basic-form w-100 d-flex justify-content-space-between align-items-center gap-2" style={{ minHeight: '2.5rem' }}>
                                                    {(INTERVAL_TYPE || []).map((item, ind) => (
                                                        <div className="d-flex align-items-center vertical-align-middle p-0 ps-1" key={ind} onClick={() => {
                                                            setFrequencyTypeSearch((prev) => item);
                                                            setIntervalTimeSearch(INTERVAL_TIME[`${item?.value}`][0]?.value)
                                                        }}>
                                                            <RadioDanger text={item.label} checked={frequencyTypeSearch?.value === item.value} />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className={`col-xl-4 col-lg-5 col-sm-5 mb-xl-0 mb-3 d-flex flex-column align-items-end`}>
                                                <label className='form-label mb-1 w-100'>{t('Date')}</label>
                                                <Nav as="ul" className="nav nav-pills nav-link-custom-select form-height-38">
                                                    {(INTERVAL_TIME[`${(frequencyTypeSearch || INTERVAL_TYPE[0])?.value}`] || []).map((item, ind) => (
                                                        <Nav.Item as="li" className="nav-item flex-grow-1 text-center" key={ind} onClick={() => handleChangeIntervalTime(item.value)}>
                                                            <div className={`nav-link m-0 ${intervalTimeSearch === item.value ? 'active' : ''}`} eventKey={item.label}>
                                                                {item.label}
                                                            </div >
                                                        </Nav.Item>
                                                    ))}
                                                </Nav>
                                            </div>
                                            <div className="col-lg-4 col-xl-4 col-sm-3 modal-date order-0 order-sm-2 order-md-0">
                                                <label className='form-label mb-1 d-none d-sm-block' style={{ color: 'transparent' }}>{t('Date')}</label>
                                                <div className="input-hasicon mb-xl-0 mb-3">
                                                    <RangeDatePicker
                                                        frequencyTypeSearch={frequencyTypeSearch}
                                                        dateSearch={dateSearch}
                                                        handleChangeDatePicker={handleChangeDatePicker}
                                                        intervalTimeSearch={intervalTimeSearch}
                                                    />
                                                    <div className="icon"><i className="far fa-calendar" /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-12 col-xl-3 gap-3 d-flex align-items-center align-items-md-end justify-content-center justify-content-sm-start flex-lg-row my-2 my-xl-0">
                                        <ButtonDanger
                                            text={t('Filter')}
                                            // isLoading={isKeywordLoading}
                                            onClick={searchData}
                                            className="btn-search-category"
                                        // disabled={isKeywordLoading}
                                        // title="Click here to Search"
                                        />
                                        <ButtonSecondary
                                            text={t('Remove Filter')}
                                            // isLoading={isKeywordLoading}
                                            onClick={() => resetData()}
                                            className="btn-search-category"
                                        // disabled={isKeywordLoading}
                                        //  title="Click here to remove filter"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 mb-3">
                        <BasicTabs tabs={tabs} tabActive={query?.category_id} handleChangeTab={handleChangeTab} />
                    </div>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header border-0"><h4 className="mb-0" style={{ fontSize: '16px', fontWeight: 900, letterSpacing: '-.4px' }}>{t('Keywords')}</h4></div>
                            <div className="card-body pt-0">
                                <div style={{ height: '100%' }}>
                                    <InfiniteScroll
                                        dataLength={keywords?.list?.length || 0}
                                        next={handleLoadMore}
                                        scrollableTarget='scrollableDiv'
                                        hasMore={isLoadMore}
                                        loader={''}
                                        scrollThreshold={'50%'}
                                    >
                                        <div className="cm-content-body form excerpt position-relative">
                                            <div className="card-body py-3 px-0 pt-0">
                                                <div>
                                                    <div>
                                                        <TableContainer
                                                            data={keywords?.list || []}
                                                            query={query}
                                                            frequencyType={query?.frequency_type}
                                                            categoryType={optionCategoryDropdown?.type}
                                                            isShowVote={isShowVote}
                                                            handleChangeSorting={handleChangeSorting}
                                                            handleVote={handleSetKeywordVote}
                                                            handleShowMore={handleShowMore}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </InfiniteScroll>
                                    <Loading isLoading={isKeywordLoading} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab.Container>
            </div>
            <Modal className="fade bd-example-modal-lg" show={!!keywordVote} centered size="md" onHide={handleCloseVote} style={{ opacity: !!chargeInfo ? 0 : 1 }}>
                <Modal.Header className="border-0 pt-4">
                    <Modal.Title>{keywordVote?.keyword?.keyword}에 투표하기</Modal.Title>
                    <svg width="24" height="24" viewBox="0 0 24 24" className="cursor-pointer" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleCloseVote}>
                        <path d="M18 18L6 6M18 6L6 18" stroke="#47494E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </Modal.Header>
                <Modal.Body className="pb-2 pt-2">
                    <Container className="px-0">
                        <Row>
                            <Col sm="12">
                                <div className="bg-purple-light d-flex justify-content-between align-items-center flex-row" style={{ borderRadius: '10px', padding: '12px 12px 12px 20px' }}>
                                    <div className="flex-grow-1 d-flex justify-content-between align-items-center">
                                        <div className="">
                                            <h4 className="mb-0 text-color-1" >{t('My Vote')}</h4>
                                        </div>
                                        <div className="text-center">
                                            <h4 className="mb-0 text-color-2" >{formatNumberWithCommas(currentTicket?.ticket_amount || 0)} <span className="ms-0 me-3 text-color-3 text-normal" style={{ fontWeight: '200' }}>개</span></h4>
                                        </div>
                                    </div>
                                    <ButtonWarning
                                        onClick={() => { setChargeInfo({ ticket_id: tickets[0]?.id || '' }); }}
                                        variant="contained"
                                        text={t('Charge')}
                                        className="py-2"
                                        style={{ maxWidth: '90px' }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div className="d-flex gap-2 justify-content-between align-items-center mt-4">
                            {[10, 100, 1000, 'all']?.map((num) => (
                                <div className="flex-grow-1 cursor-pointer card-push-point" style={{ padding: '12px 16px', borderRadius: '10px', border: '1px solid #E9EBF1' }} onClick={() => handlePollVote(num)}>
                                    <h3 className="text-color-2 text-center fs-16 fs-md-auto mb-0">{num === 'all' ? t('Use All') : <>+{num}</>}</h3>
                                </div>
                            ))}
                        </div>
                        <Row>
                            <Col sm="12 mt-3 d-flex gap-2">
                                <div className="cursor-pointer d-flex justify-content-center align-items-center text-center " style={{ padding: '8px', borderRadius: '12px', border: '1px solid #E9EBF1' }} onClick={() => setTicketAmountVote(0)} >
                                    <h3 className="text-color-5 text-center fs-16 fs-md-auto mb-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                            <path d="M27.1288 22.5C26.1109 25.6939 23.1954 28 19.7576 28C15.4732 28 12 24.4183 12 20C12 15.5817 15.4732 12 19.7576 12C22.2706 12 24.5045 13.2322 25.9221 15.1428M26.4773 16C26.3099 15.7015 26.1243 15.4153 25.9221 15.1428M25.9221 15.1428L24.1212 17H28V13L25.9221 15.1428ZM26.0606 16.6667L27.2727 15" stroke="#A7ABB5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                    </h3>
                                </div>
                                <div className="cursor-pointer d-flex justify-content-center align-items-center text-center flex-grow-1 " style={{ padding: '16px', borderRadius: '12px', border: '1px solid #E9EBF1' }}  >
                                    <h3 className="text-color-5 text-center fs-16 fs-md-auto mb-0">
                                        {formatNumberWithCommas(ticketAmountVote || 0)} <span className="ms-1">{t('use your right to vote')}</span>
                                    </h3>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="text-center border-0 pb-4">
                    <ButtonPrimary
                        disabled={isLoading || Number(ticketAmountVote) === 0}
                        isLoading={isLoading}
                        onClick={handleVote}
                        variant="contained"
                        text={t('Vote')}
                        className="py-3 m-0"
                    />
                </Modal.Footer>
            </Modal>
            <Modal className="fade bd-example-modal-lg" show={!!keywordVotedInfo} size="md" centered>
                <Modal.Header className="border-0 pt-4">
                    <Modal.Title className="text-center m-auto">
                        {
                            keywordVotedInfo?.step === 2 ? <>
                                {t('The vote has been completed!')}
                            </> :
                                (
                                    i18n?.language === 'en' ? <>
                                        {t('We delivered')} {formatNumberWithCommas(keywordVotedInfo?.history?.ticket_amount || 0)} {t('votes to')} {keywordVotedInfo?.keyword?.keyword || ''}
                                    </> : <>
                                        {keywordVotedInfo?.keyword?.keyword || ''} {t('votes to')} {formatNumberWithCommas(keywordVotedInfo?.history?.ticket_amount || 0)} {t('We delivered')}
                                    </>
                                )
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pb-2 pt-2">
                    <Container className="px-0">
                        <Col sm="12" md="12" >
                            {keywordVotedInfo?.step === 2 ? <div className="d-flex justify-content-around align-items-center py-2">
                                {t('Your vote will be reflected in the rankings after one hour.')}
                            </div> : (<div className="d-flex justify-content-around align-items-center py-2">
                                <div className="text-center">
                                    <div className="mb-0 text-center">{t('Participation Index')}</div>
                                    <h3 className="mb-0 text-center">{formatNumberWithCommas(keywordVotedInfo?.p_index_before || 0)}</h3>
                                </div>
                                <div className="text-center">
                                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.7725 23.4657L8.3261 16.2228C7.83674 15.7468 7 16.0839 7 16.7571V31.2429C7 31.9161 7.83674 32.2532 8.3261 31.7772L15.7725 24.5343C16.0758 24.2392 16.0758 23.7608 15.7725 23.4657Z" fill="#FFD466" />
                                        <path d="M27.7725 23.4657L20.3261 16.2228C19.8367 15.7468 19 16.0839 19 16.7571V31.2429C19 31.9161 19.8367 32.2532 20.3261 31.7772L27.7725 24.5343C28.0758 24.2392 28.0758 23.7608 27.7725 23.4657Z" fill="#FFBE1A" />
                                        <path d="M39.7725 23.4657L32.3261 16.2228C31.8367 15.7468 31 16.0839 31 16.7571V31.2429C31 31.9161 31.8367 32.2532 32.3261 31.7772L39.7725 24.5343C40.0758 24.2392 40.0758 23.7608 39.7725 23.4657Z" fill="#E7A600" />
                                    </svg>
                                </div>
                                <div className="text-center">
                                    <div className="mb-0 text-center">{t('Participation Index')}</div>
                                    <h3 className="mb-0 text-center">{formatNumberWithCommas(keywordVotedInfo?.p_index_after || 0)}</h3>
                                </div>
                            </div>)
                            }
                        </Col>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="text-center border-0 pb-4">
                    <Button
                        variant=""
                        type="button"
                        className="btn btn-md m-0 btn-block btn-primary m-auto"
                        onClick={() => setKeywordVotedInfo((_prev) => (_prev?.step === 2 ? null : { ..._prev, step: 2 }))}
                        disabled={isLoading}
                        style={{ borderRadius: '12px', width: '150px' }}
                    >
                        {isLoading ? <Spinner size="sm"></Spinner> : t('Confirm')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal className="fade bd-example-modal-lg" show={!!confirmLogin} size="md" centered >
                <Modal.Header className="border-0 pt-4">
                    <Modal.Title className="text-center m-auto">
                        <div className="text-center">
                            {t('Would you like to log in?')}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pb-2 pt-2">
                    <Container className="px-0">
                        <Col sm="12" md="12" >
                            <div className="d-flex justify-content-around align-items-center py-2">
                                {t('You must log in to participate in the vote.')}
                            </div>
                        </Col>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="text-center border-0 pb-4 justify-content-center gap-3">
                    <ButtonPrimary
                        onClick={() => setConfirmLogin((_prev) => false)}
                        variant="outlined"
                        text={t('Cancel')}
                        className=" m-0"
                        style={{ maxWidth: '150px', padding: '0.57rem 1rem' }}
                    />
                    <ButtonPrimary
                        onClick={goToLogin}
                        variant="contained"
                        text={t('Go to log in')}
                        className=" m-0"
                        style={{ maxWidth: '150px', padding: '0.57rem 1rem' }}
                    />
                </Modal.Footer>
            </Modal>
            <Modal className="fade bd-example-modal-lg" show={!!chargeInfo} centered onHide={handleCloseChargeInfo}>
                <Modal.Header className="border-0 pt-4 pb-0">
                    <Modal.Title className="text-start">
                        {t('Purchase voting rights')}
                    </Modal.Title>
                    <svg width="24" height="24" viewBox="0 0 24 24" className="cursor-pointer" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleCloseChargeInfo}>
                        <path d="M18 18L6 6M18 6L6 18" stroke="#47494E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </Modal.Header>
                <Modal.Header className="border-0 pt-0">
                    {t('You can support your favorite celebrity by using your voting rights.')}
                </Modal.Header>
                <Modal.Body className="pb-2 pt-2">
                    <Container className="px-0">
                        {!!chargeInfo?.status ? <PaymentNotification type={chargeInfo?.status === 'SUCCESS' ? 'success' : 'error'} /> : (
                            <>
                                <Row>
                                    <Col sm="12">
                                        <div className="bg-purple-light d-flex justify-content-between align-items-center flex-row" style={{ borderRadius: '10px', padding: '12px 12px 12px 20px' }}>
                                            <div className="flex-grow-1 d-flex justify-content-between align-items-center">
                                                <div className="">
                                                    <h4 className="mb-0 text-color-1" >{t('Voting rights in possession')}</h4>
                                                </div>
                                                <div className="text-center">
                                                    <h4 className="mb-0 text-color-2" >{formatNumberWithCommas(currentTicket?.ticket_amount || 0)} <span className="ms-0 me-3 text-color-3 text-normal" style={{ fontWeight: '200' }}>개</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="d-flex flex-column gap-2 justify-content-start align-items-center mt-4">
                                    {tickets?.map((ticket) => (
                                        <div className="d-flex justify-content-center cursor-pointer card-push-point w-100" htmlFor={`charge-${ticket?.id}`} style={{ padding: '10px 16px', borderRadius: '10px', border: '1px solid #E9EBF1' }} onClick={() => setChargeInfo((_prev) => ({ ..._prev, ticket_id: ticket?.id }))}>
                                            <div className="form-check flex-grow-1 align-self-center ps-1 d-flex align-items-center">
                                                <RadioPrimary text='' checked={ticket?.id === chargeInfo?.ticket_id} />
                                                <label className="form-check-label fs-16 ms-0 mt-0" style={{ paddingTop: '3px' }}>
                                                    {formatNumberWithCommas(ticket?.amount)} 개
                                                </label>
                                            </div>
                                            <ButtonWarning
                                                variant="contained"
                                                text={`${formatNumberWithCommas(ticket?.price_kr)}원`}
                                                className="py-2"
                                                style={{ maxWidth: '100px' }}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <Row>
                                    <Col sm="12 mt-3">
                                        <h5>
                                            {t('Voting Rights Usage Guide')}
                                        </h5>
                                        <ul style={{ fontSize: '12px' }} className="ms-3">
                                            <li className="" style={{ listStyleType: 'disc' }}>
                                                {t('After purchasing voting rights, you can use them within the Rankify service.')}
                                            </li>
                                            <li className="" style={{ listStyleType: 'disc' }}>
                                                {t('The price of the voting rights includes VAT.')}
                                            </li>
                                            <li className="" style={{ listStyleType: 'disc' }}>
                                                {t('Refunds for voting rights are only processed for unused voting rights after confirmation.')}
                                            </li>
                                            <li className="" style={{ listStyleType: 'disc' }}>
                                                {t('If you encounter any issues with the purchase or use of voting rights, please contact us with the relevant screen at the following email:')}
                                            </li>
                                            <li className="" style={{ listStyleType: 'disc' }}>
                                                {t('Inquiry Email')}: <a href="mailto:info@rankify.best" className="text-decoration-underline">info@rankify.best</a>
                                            </li>
                                            <li className="" style={{ listStyleType: 'disc' }}>
                                                {t('We are not responsible for the loss or incorrect use of voting rights due to member negligence.')}
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </>)}
                    </Container>
                </Modal.Body>
                <Modal.Footer className="text-center border-0 pb-4">
                    <ButtonPrimary
                        disabled={isLoading}
                        isLoading={isLoading}
                        onClick={() => { !!chargeInfo?.status ? handleCloseChargeInfo() : handlePurchase() }}
                        variant="contained"
                        text={!!chargeInfo?.status ? t('Close') : t('Purchase')}
                        className="py-3 m-0"
                    />
                </Modal.Footer>
            </Modal>
            <Modal className="fade bd-example-modal-lg" show={!!categoriesLevel3} centered onHide={() => setCategoriesLevel3(null)}>
                <Modal.Header className="border-0 pt-4 pb-0">
                    <Modal.Title className="text-start">
                        {t('Subcategory')}
                    </Modal.Title>
                    <svg width="24" height="24" viewBox="0 0 24 24" className="cursor-pointer" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setCategoriesLevel3(null)}>
                        <path d="M18 18L6 6M18 6L6 18" stroke="#47494E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </Modal.Header>
                <Modal.Body className="pb-2 pt-2">
                    <Container className="px-0">
                        <Row>
                            <Col sm="12" className="mb-3">
                                <ul style={{ fontSize: '14px' }} className="ms-3">
                                    {categoriesLevel3?.map((category) => (
                                        <li className="mb-1" style={{ listStyleType: 'disc' }} key={category}>
                                            {category}
                                        </li>
                                    ))}
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Category;
import React, { useEffect, useRef } from "react";
import Sort from "../Sort";
import { useTranslation } from "react-i18next";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CATEGORY_TYPE, FREQUENCY_TYPE } from "helpers/constans";

const Header = ({ query = { sort_by: '', order_by: 'DESC' }, categoryType = String(CATEGORY_TYPE.TREND_INDEX), frequencyType = String(FREQUENCY_TYPE.DAILY), isShowVote = false, handleChangeSorting = undefined, style = {}, widthColumn, }: any, ref: any) => {

    const { t, i18n } = useTranslation();


    const handleSort = (sort: any) => {
        handleChangeSorting && handleChangeSorting(sort);
    };
    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("The Participation Index reflects user engagement in the index. Use your participation to boost the keywords you're interested in!")}
        </Tooltip>
    );
    const textRankChange = {
        [String(FREQUENCY_TYPE.DAILY)]: t('Index Chart (last 30 days)'),
        [String(FREQUENCY_TYPE.WEEKLY)]: t('Index Chart (last 12 weeks)'),
        [String(FREQUENCY_TYPE.MONTHLY)]: t('Index Chart (last 6 months)'),
    };

    return (
        <>
            <thead style={style}>
                <tr>
                    <th className="text-center cursor-pointer align-middle"
                        ref={el => { if (ref?.current) { ref.current[0] = el } }}
                        {...(widthColumn ? { width: widthColumn[0]?.offsetWidth } : { width: '50' })}
                    >
                        <div>{t('Rank')}</div>
                    </th>
                    <th className="text-center cursor-pointer align-middle"
                        ref={el => { if (ref?.current) { ref.current[1] = el } }}
                        {...(widthColumn ? { width: widthColumn[1]?.offsetWidth } : { width: i18n?.language === 'en' ? '140' : '120' })}
                    >{t('Keyword')}</th>
                    {(isShowVote && (String(frequencyType) === String(FREQUENCY_TYPE.DAILY))) && (
                        <><th className="text-center pe-4 cursor-pointer align-middle"
                            ref={el => { if (ref?.current) { ref.current[11] = el } }}
                            {...(widthColumn ? { width: widthColumn[11]?.offsetWidth } : { width: i18n?.language === 'en' ? '130' : '100' })}
                        >
                            <div className="">{t('User Participation')}</div>
                        </th>
                        </>)
                    }
                    <th className="text-center cursor-pointer align-middle"
                        ref={el => { if (ref?.current) { ref.current[2] = el } }}
                        {...(widthColumn ? { width: widthColumn[2]?.offsetWidth } : { width: i18n?.language === 'en' ? '100' : '80' })}
                    >{t('Category')}</th>
                    <th className="text-center cursor-pointer align-middle"
                        ref={el => { if (ref?.current) { ref.current[3] = el } }}
                        {...(widthColumn ? { width: widthColumn[3]?.offsetWidth } : { width: i18n?.language === 'en' ? '140' : '120' })}
                    >{t('Subcategory')}</th>
                    <th className="text-center cursor-pointer align-middle" dangerouslySetInnerHTML={{ __html: textRankChange[String(query?.frequency_type)] }}
                        ref={el => { if (ref?.current) { ref.current[4] = el } }}
                        {...(widthColumn ? { width: widthColumn[4]?.offsetWidth } : { width: i18n?.language === 'en' ? '200' : '180' })}
                    ></th>
                    <th className="text-end cursor-pointer align-middle" onClick={() => handleSort({ sort_by: 'index', order_by: (query?.sort_by === 'index' && query?.order_by === 'DESC') ? 'ASC' : 'DESC' })}
                        ref={el => { if (ref?.current) { ref.current[5] = el } }}
                        {...(widthColumn ? { width: widthColumn[5]?.offsetWidth } : { width: i18n?.language === 'en' ? '120' : '100' })}
                    >
                        {t('Trend Index')}
                        <Sort sort_by={query?.sort_by} order_by={query?.order_by} name="index" />
                    </th>
                    {(String(categoryType) === String(CATEGORY_TYPE.STOCK)) && <th
                        className="text-end cursor-pointer align-middle"
                        onClick={() => handleSort({ sort_by: 'stock_index', order_by: (query?.sort_by === 'stock_index' && query?.order_by === 'DESC') ? 'ASC' : 'DESC' })}
                        ref={el => { if (ref?.current) { ref.current[6] = el } }}
                        {...(widthColumn ? { width: widthColumn[6]?.offsetWidth } : { width: i18n?.language === 'en' ? '120' : '100' })}
                    >
                        {t('Stock Index')}
                        <Sort sort_by={query?.sort_by} order_by={query?.order_by} name="stock_index" />
                    </th>}
                    <th className="text-end cursor-pointer align-middle"
                        onClick={() => handleSort({ sort_by: 'search_index', order_by: (query?.sort_by === 'search_index' && query?.order_by === 'DESC') ? 'ASC' : 'DESC' })}
                        ref={el => { if (ref?.current) { ref.current[7] = el } }}
                        {...(widthColumn ? { width: widthColumn[7]?.offsetWidth } : { width: i18n?.language === 'en' ? '120' : '100' })}
                    >
                        {t('Search Index')}
                        <Sort sort_by={query?.sort_by} order_by={query?.order_by} name="search_index" />
                    </th>
                    <th className="text-end cursor-pointer align-middle"
                        onClick={() => handleSort({ sort_by: 'viral_index', order_by: (query?.sort_by === 'viral_index' && query?.order_by === 'DESC') ? 'ASC' : 'DESC' })}
                        ref={el => { if (ref?.current) { ref.current[8] = el } }}
                        {...(widthColumn ? { width: widthColumn[8]?.offsetWidth } : { width: i18n?.language === 'en' ? '120' : '100' })}
                    >
                        {t('Viral Index')}
                        <Sort sort_by={query?.sort_by} order_by={query?.order_by} name="viral_index" />
                    </th>
                    <th className="text-end pe-4 cursor-pointer align-middle"
                        onClick={() => handleSort({ sort_by: 'news_index', order_by: (query?.sort_by === 'news_index' && query?.order_by === 'DESC') ? 'ASC' : 'DESC' })}
                        ref={el => { if (ref?.current) { ref.current[9] = el } }}
                        {...(widthColumn ? { width: widthColumn[9]?.offsetWidth } : { width: i18n?.language === 'en' ? '120' : '90' })}
                    >
                        <div>
                            {t('News Index')}
                            <Sort sort_by={query?.sort_by} order_by={query?.order_by} name="news_index" />
                        </div>
                    </th>
                    {isShowVote && (
                        <>
                            <th className="text-end pe-4 cursor-pointer align-middle"
                                onClick={() => handleSort({ sort_by: 'p_index', order_by: (query?.sort_by === 'p_index' && query?.order_by === 'DESC') ? 'ASC' : 'DESC' })}
                                ref={el => { if (ref?.current) { ref.current[10] = el } }}
                                {...(widthColumn ? { width: widthColumn[10]?.offsetWidth } : { width: i18n?.language === 'en' ? '110' : '100' })}
                            >
                                <div>
                                    {t('P Index')}
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                    >
                                        <Button variant="light" className="ms-1 p-0 fs-10" style={{ width: '16px', height: '16px', fontSize: '9px' }}>?</Button>
                                    </OverlayTrigger>
                                    <Sort sort_by={query?.sort_by} order_by={query?.order_by} name="p_index" />
                                </div>
                            </th>
                        </>)
                    }
                </tr>
            </thead >
        </>
    );
};

export default React.memo(React.forwardRef(Header));

import { formatNumberWithCommas } from "helpers/format";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import RankingChart from "../../RankingChart";
import { Button, Card, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CATEGORY_TYPE, FREQUENCY_TYPE } from "helpers/constans";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import CustomSkeleton from "ui/components/loadings/CustomSkeleton";
import AgeChart from "../../AgeChart";
import GenderChart from "../../GenderChart";
import ButtonDangerLight from "ui/components/buttons/ButtonDangerLight";
import ButtonDanger from "ui/components/buttons/ButtonDanger";

const Row = ({ data, widthChart, categoryType = String(CATEGORY_TYPE.TREND_INDEX), frequencyType = String(FREQUENCY_TYPE.DAILY), isShowVote = false, handleVote, handleShowMore }: any) => {
    const { t, i18n } = useTranslation();
    const [isShowSeeTrend, setIsShowSeeTrend] = useState<boolean>(false);
    const refTableB = useRef<any>();
    const refHeaderTableB = useRef<any>();

    const [divHeight, setDivHeight] = useState(100);
    const isDark = document.body.getAttribute('data-theme-version') === 'dark';
    const handleActionVote = () => {
        handleVote && handleVote(data);
    }
    const handleDuplicateCategory = (categories: any[] = []) => {
        return categories?.reduce((ob, item) => {
            if (ob?.listId?.includes(item?.id)) {
                return ob;
            }
            return ({ listId: [...ob?.listId, item?.id], listName: [...ob?.listName, item?.name] });
        }, { listId: [], listName: [] })
    }
    const showMore = (keywords: string[]) => {
        handleShowMore && handleShowMore(keywords);
    };
    const categories_level_2 = handleDuplicateCategory(data?.category_level_2)?.listName || [];
    const categories_level_3 = handleDuplicateCategory(data?.category_level_3)?.listName || [];
    const toggleShowSeeTrend = () => {
        setIsShowSeeTrend((_prev) => !_prev)
    };

    const calculatePercentageGender = useMemo(() => {
        const { search_age_10_value, search_age_20_value, search_age_30_value, search_age_40_value, search_age_50_value } = data;
        const ob = { search_age_10_value, search_age_20_value, search_age_30_value, search_age_40_value, search_age_50_value };
        const total: any = Object.values(ob).reduce((acc: any, value: any) => acc + value, 0);

        const entries = Object.entries(ob);

        const percentages: any = {};
        let accumulatedPercentage = 0;

        for (let i = 0; i < entries.length - 1; i++) {
            const [key, value]: any = entries[i];
            const percentage: any = ((value / total) * 100).toFixed(2);
            percentages[key] = `${percentage}%`;
            accumulatedPercentage += parseFloat(percentage);
        }

        const lastKey = entries[entries.length - 1][0];
        const lastPercentage = (100 - accumulatedPercentage).toFixed(2);
        percentages[lastKey] = `${lastPercentage}%`;

        return percentages;
    }, [data]);

    const calculatePercentageAge = useMemo(() => {
        const { search_male_value, search_female_value } = data;
        const ob = { search_male_value, search_female_value };
        const total: any = Object.values(ob).reduce((acc: any, value: any) => acc + value, 0);

        const entries = Object.entries(ob);

        const percentages: any = {};
        let accumulatedPercentage = 0;

        for (let i = 0; i < entries.length - 1; i++) {
            const [key, value]: any = entries[i];
            const percentage: any = ((value / total) * 100).toFixed(2);
            percentages[key] = `${percentage}%`;
            accumulatedPercentage += parseFloat(percentage);
        }

        const lastKey = entries[entries.length - 1][0];
        const lastPercentage = (100 - accumulatedPercentage).toFixed(2);
        percentages[lastKey] = `${lastPercentage}%`;

        return percentages;
    }, [data]);


    useEffect(() => {
        setIsShowSeeTrend((_prev) => false);
    }, [data]);

    useEffect(() => {
        if (refTableB.current) {
            setDivHeight(refTableB.current.clientHeight - (refHeaderTableB.current.clientHeight));
        }
    }, [isShowSeeTrend]);
    return (
        <>
            <tr>
                <td className="text-center">
                    <CustomSkeleton isDark={isDark} isLoading={!!data?.isLoading} height={30}>
                        {data?.rank}
                    </CustomSkeleton>
                </td>
                <td className="text-center">
                    <CustomSkeleton isDark={isDark} isLoading={!!data?.isLoading} height={30}>
                        <div style={{ minWidth: '100px' }} className="m-auto" >
                            {data?.keyword?.keyword || ''}
                        </div>
                    </CustomSkeleton>
                </td>
                {
                    (isShowVote && (String(frequencyType) === String(FREQUENCY_TYPE.DAILY))) && (
                        <>
                            <td className="text-center">
                                <CustomSkeleton isDark={isDark} isLoading={!!data?.isLoading} height={30}>
                                    <div className="text-center d-flex justify-content-center">
                                        <ButtonDanger
                                            text={t('Vote')}
                                            className="py-1 m-auto btn-font-size-vote"
                                            variant="outlined"
                                            // isLoading={isKeywordLoading}
                                            onClick={handleActionVote}
                                            style={{ maxWidth: '86px', float: 'inline-end', maxHeight: '30px' }}
                                        />
                                    </div>
                                </CustomSkeleton>
                            </td>
                        </>
                    )}
                <td className="text-center" >
                    <CustomSkeleton isDark={isDark} isLoading={!!data?.isLoading} height={30}>
                        <div style={{ minWidth: '70px' }}>
                            {categories_level_2?.map((cate: any, i: number) => <Fragment key={i}>{cate} <br /></Fragment>) || ''}
                        </div>
                    </CustomSkeleton>
                </td>
                <td className="text-center">
                    <CustomSkeleton isDark={isDark} isLoading={!!data?.isLoading} height={30}>
                        <div style={{ minWidth: '100px' }}>
                            {categories_level_3?.map((cate: any, index: number) => (index < 4) ? <Fragment key={index}>{cate} <br /></Fragment> : <Fragment key={index}></Fragment>) || ''}
                            {categories_level_3?.length > 4 && <span className="text-decoration-underline cursor-pointer fs-12" onClick={() => showMore(categories_level_3)}>{t('Show more')}</span>}
                        </div>
                    </CustomSkeleton>
                </td>
                <td>
                    <CustomSkeleton isDark={isDark} isLoading={!!data?.isLoading} height={30}>
                        <div className="card-body pt-0 custome-tooltip p-0 text-center d-flex justify-content-center align-items-end" onClick={toggleShowSeeTrend}>
                            <div style={{ width: '100px', height: '30px' }} className="cursor-pointer">
                                <RankingChart data={data?.ranking_changes_chart} name={data?.keyword?.keyword || ''} />
                            </div>
                            <button type="button" className="btn btn-xs ms-1  fs-10 p-1" style={{ border: '0px solid #eaeaea', fontSize: '10px', minWidth: '16px', borderRadius: '16px' }}>
                                {/* <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 16l6-5.668L16.59 9 12 13.327 7.41 9 6 10.332z" fill="#B0BAC8" fill-rule="nonzero"></path></svg> */}
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: isShowSeeTrend ? 'rotate(180deg)' : 'rotate(0deg)' }}>
                                    <path d="M3 6L8 11L13 6" stroke="#C7C7C7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </button>
                        </div>
                    </CustomSkeleton>
                </td>
                <td className="text-end">
                    <CustomSkeleton isDark={isDark} isLoading={!!data?.isLoading} height={30}><div style={{ minWidth: i18n?.language === 'en' ? '100px' : '70px' }}>
                        {formatNumberWithCommas(data?.index)}
                    </div>
                    </CustomSkeleton>
                </td>
                {
                    (String(categoryType) === String(CATEGORY_TYPE.STOCK)) ? (
                        <td className="text-end">
                            <CustomSkeleton isDark={isDark} isLoading={!!data?.isLoading} height={30}><div style={{ minWidth: i18n?.language === 'en' ? '100px' : '70px' }}>
                                {formatNumberWithCommas(data?.stock_index)}
                            </div>
                            </CustomSkeleton>
                        </td>
                    ) : <></>
                }
                <td className="text-end">
                    <CustomSkeleton isDark={isDark} isLoading={!!data?.isLoading} height={30}>
                        <div style={{ minWidth: i18n?.language === 'en' ? '100px' : '70px' }}>
                            {formatNumberWithCommas(data?.search_index)}
                        </div>
                    </CustomSkeleton>
                </td>
                <td className="text-end">
                    <CustomSkeleton isDark={isDark} isLoading={!!data?.isLoading} height={30}><div style={{ minWidth: i18n?.language === 'en' ? '90px' : '70px' }}>
                        {formatNumberWithCommas(data?.viral_index)}
                    </div>
                    </CustomSkeleton>
                </td>
                <td className="text-end pe-4">
                    <CustomSkeleton isDark={isDark} isLoading={!!data?.isLoading} height={30}><div style={{ minWidth: i18n?.language === 'en' ? '90px' : '70px' }}>
                        {formatNumberWithCommas(data?.news_index)}
                    </div>
                    </CustomSkeleton>
                </td>
                {
                    isShowVote && (
                        <>
                            <td className="text-end pe-4">
                                <CustomSkeleton isDark={isDark} isLoading={!!data?.isLoading} height={30}><div style={{ minWidth: i18n?.language === 'en' ? '90px' : '70px' }}>
                                    {formatNumberWithCommas(data?.p_index)}
                                </div>
                                </CustomSkeleton>
                            </td>
                        </>
                    )}
            </tr>
            {isShowSeeTrend && (<tr>
                <td colSpan={9 + (String(categoryType) === String(CATEGORY_TYPE.STOCK) ? 1 : 0) + (isShowVote ? (String(frequencyType) === String(FREQUENCY_TYPE.DAILY) ? 2 : 1) : 0)} className="position-relative box-sub-row-table" >
                    <div className={`card-body pt-0 p-0 text-center d-flex gap-3 ${window.innerWidth < 750 ? 'flex-column' : 'flex-row'} justify-content-between align-items-end`}>
                        <div className="m-auto" style={{ width: window.innerWidth < 750 ? '100%' : '65%', height: `100%` }}>
                            <Card className="mb-0 rounded-0">
                                <Card.Header className="py-2 px-2" ref={refHeaderTableB}>
                                    <Card.Title style={{ fontSize: '.875rem' }}>{t('Keyword')}</Card.Title>
                                </Card.Header>
                                <Card.Body className="p-2 py-0 mb-0">
                                    <RankingChart
                                        data={data?.ranking_changes_chart}
                                        name={data?.keyword?.keyword || ''}
                                        height={window.innerWidth < 750 ? 200 : (divHeight - 16)}
                                        style={{ transform: 'translateY(0px)' }}
                                        isShowXaxis={true}
                                        isShowYaxis={true}
                                        isShowTooltip={true}
                                        showAxisBorder={true}
                                        fillColor="#FDEBC7"
                                        fillOpacity={0}
                                        markersSize={3}
                                        tickAmountXaxis={String(frequencyType) === String(FREQUENCY_TYPE.DAILY) ? 10 : 6}
                                    />
                                </Card.Body>
                            </Card>
                        </div>
                        <div ref={refTableB} className={`m-auto d-flex gap-4 ${window.innerWidth < 750 ? 'flex-row' : 'flex-column'}  justify-content-space-between`} style={{ width: window.innerWidth < 750 ? '100%' : '35%', height: `100%` }}>
                            <div style={{ width: window.innerWidth < 750 ? '40%' : '100%' }}>
                                <Card className="mb-0 rounded-0" style={{ height: '100%' }}>
                                    <Card.Header className="py-2 px-2" >
                                        <Card.Title style={{ fontSize: '.875rem' }}>{t('Gender')}</Card.Title>
                                    </Card.Header>
                                    <Card.Body className="p-2 mb-2">
                                        <Table bordered className="mb-0">
                                            <thead>
                                                <tr>
                                                    <th className="p-2 text-center fs-13">{t('Gender_Male')}</th>
                                                    <th className="p-2 text-center fs-13">{t('Gender_FeMale')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="p-1 text-center fs-13">
                                                        {calculatePercentageAge?.search_male_value}
                                                    </td>
                                                    <td className="p-1 text-center fs-13">
                                                        {calculatePercentageAge?.search_female_value}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div style={{ width: window.innerWidth < 750 ? '60%' : '100%' }}>
                                <Card className="mb-0 rounded-0" style={{ height: '100%' }}>
                                    <Card.Header className="py-2 px-2">
                                        <Card.Title style={{ fontSize: '.875rem' }}>{t('Age')}</Card.Title>
                                    </Card.Header>
                                    <Card.Body className="p-2 mb-2">
                                        <Table bordered className="mb-0">
                                            <thead>
                                                <tr>
                                                    <th className="p-2 text-center fs-13">{t('Age_10')}</th>
                                                    <th className="p-2 text-center fs-13">{t('Age_20')}</th>
                                                    <th className="p-2 text-center fs-13">{t('Age_30')}</th>
                                                    <th className="p-2 text-center fs-13">{t('Age_40')}</th>
                                                    <th className="p-2 text-center fs-13">{t('Age_50')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="p-1 text-center fs-13">
                                                        {calculatePercentageGender?.search_age_10_value}
                                                    </td>
                                                    <td className="p-1 text-center fs-13">
                                                        {calculatePercentageGender?.search_age_20_value}
                                                    </td>
                                                    <td className="p-1 text-center fs-13">
                                                        {calculatePercentageGender?.search_age_30_value}
                                                    </td>
                                                    <td className="p-1 text-center fs-13">
                                                        {calculatePercentageGender?.search_age_40_value}
                                                    </td>
                                                    <td className="p-1 text-center fs-13">
                                                        {calculatePercentageGender?.search_age_50_value}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                    <div className="position-absolute" style={{ top: '10px', right: '10px' }} onClick={toggleShowSeeTrend}>
                        <svg width="24" height="24" viewBox="0 0 24 24" className="cursor-pointer" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 18L6 6M18 6L6 18" stroke="#47494E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                    </div>
                </td>
            </tr>)}
        </>
    );
};

export default React.memo(Row);

import voteApi from "api/voteApi";

export const postVote = async (data: any = {}) => {
    try{
      const response = await voteApi.postVoting(data)
      return response;
    }catch (error) {
      return error;
    }
  };

  export const getTicket = async (data: any = {}) => {
    try{
      const response = await voteApi.getTickets(data)
      return response;
    }catch (error) {
      return error;
    }
};

export const postPaymentProcess = async (data: any = {}) => {
    try{
      const response = await voteApi.postPaymentProcess(data)
      return response;
    }catch (error) {
      return error;
    }
  };
import React, { useEffect, useRef, useState } from "react";
import Body from "./Body";
import Header from "./Header";


const TableContainer = ({ query = {}, data = [], categoryType, frequencyType, isShowVote = false, handleChangeSorting, handleVote, handleShowMore }: any) => {

    const [isScrollBottom, setIsScrollBottom] = useState<boolean>(false);
    const [scrollLeft, setScrollLeft] = useState<any>(0);

    const thRefs = useRef<any>([]);
    const tableRef = useRef<any>(null);
    const warperTableRef = useRef<any>(null);
    const elementFixedRef = useRef<any>(null);
    const elementHeaderFixedRef = useRef<any>(null);
    const elementTable: any = tableRef?.current?.getBoundingClientRect() || {};
    const elementWarperTable: any = warperTableRef?.current?.getBoundingClientRect() || {};

    const [isShowHeaderTableFixed, setIsShowHeaderTableFixed] = useState<boolean>(false);

    // const { events } = useDraggable(warperTableRef);

    const onSorting = (sort: any) => {
        handleChangeSorting && handleChangeSorting(sort);
    };
    const onVote = (info: any) => {
        handleVote && handleVote(info);
    };
    const onShowMore = (more: any) => {
        handleShowMore && handleShowMore(more);
    };

    const handleScroll = (e: any) => {
        setScrollLeft((prev: any) => e?.target?.scrollLeft)
        if (warperTableRef?.current && elementFixedRef?.current) {
            // If warperTableRef scrolls, then scroll elementFixedRef as well
            if (e.target === warperTableRef.current) {
                elementFixedRef.current.scrollLeft = e?.target?.scrollLeft;
                elementHeaderFixedRef.current.scrollLeft = e?.target?.scrollLeft;
            }
            // If elementFixedRef scrolls, then scroll warperTableRef as well
            else if (e.target === elementFixedRef.current) {
                warperTableRef.current.scrollLeft = e?.target?.scrollLeft;
            }
        }

    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document?.documentElement?.scrollTop || 0;
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const MARGIN_BOTTOM_HEIGHT = 170; // 170px
            if ((scrollTop + windowHeight + MARGIN_BOTTOM_HEIGHT) >= documentHeight) {
                setIsScrollBottom((prev) => true);
            } else {
                setIsScrollBottom((prev) => false);
            }

            const rect = warperTableRef.current.getBoundingClientRect();
            const distanceFromTop = rect.top;
            if (distanceFromTop <= 82) {
                elementHeaderFixedRef.current.scrollLeft = elementFixedRef?.current?.scrollLeft;
                setIsShowHeaderTableFixed((prev) => true);
            } else {
                setIsShowHeaderTableFixed((prev) => false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div className="overflow-x-auto overflow-y-hidden" ref={warperTableRef} onScroll={handleScroll} >
                <table className={`table table-bordered display mb-0 orderbookTable dataTable no-footer sticky-table-ranking-keyword table-ranking-keyword ${Number(scrollLeft || 0) > 0 && 'is-scroll'}`} ref={tableRef}>
                    <Header ref={thRefs} query={query} frequencyType={frequencyType} categoryType={categoryType} handleChangeSorting={onSorting} isShowVote={isShowVote} />
                    <Body data={data} frequencyType={frequencyType} categoryType={categoryType} handleVote={onVote} handleShowMore={onShowMore} isShowVote={isShowVote} />
                </table>
            </div>
            <div
                className={`table-custom-pagination overflow-x-auto m-0 hidden-scrollbar-os ${isShowHeaderTableFixed ? '' : 'd-none'}`}
                style={{
                    position: 'fixed',
                    top: 'var(--header-height)',
                    zIndex: 9,
                    left: elementWarperTable?.left,
                    width: elementWarperTable?.width,
                    right: 0,

                }}
                ref={elementHeaderFixedRef}
            >
                <table className={`table table-bordered display mb-0 orderbookTable dataTable table-fixed-header no-footer sticky-table-ranking-keyword table-ranking-keyword ${Number(scrollLeft || 0) > 0 && 'is-scroll'}`} style={{ height: 'auto', tableLayout: 'fixed' }}>
                    <Header widthColumn={thRefs?.current} style={{ width: elementTable?.width }} query={query} frequencyType={frequencyType} categoryType={categoryType} handleChangeSorting={onSorting} isShowVote={isShowVote} />
                </table>
            </div>
            <div
                className="overflow-x-auto color-scrollbar-os"
                style={{
                    position: 'fixed',
                    zIndex: 999,
                    left: elementWarperTable?.left,
                    bottom: 2,
                    width: elementWarperTable?.width,
                    right: 0,
                    // opacity: isScrollBottom ? 0 : 1,
                }}
                ref={elementFixedRef}
                onScroll={handleScroll}
            >
                <div style={{ width: `${elementTable?.width}px`, height: '10px' }}></div>
            </div>
        </>
    );
};

export default React.memo(TableContainer);

import moment from 'moment';
import { useContext, useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
//Import 
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { rankingCategories as onGetCategories } from 'store/thunks';
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { ThemeContext } from '../../../context/ThemeContext';
import RecentTransaction from '../../elements/dashboard/RecentTransaction';
import { useDraggable } from "react-use-draggable-scroll";

export function MainComponent() {
	const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
	const { events } = useDraggable(ref);

	const [query, setQuery] = useQueryParams({
		// time_request: withDefault(NumberParam, 0),
		limit: withDefault(NumberParam, 10),
		date: withDefault(StringParam, moment().subtract(2, 'days').format('Y-MM-DD')),
		frequency_type: withDefault(StringParam, "1"),
	});

	const dispatch: any = useDispatch();

	const selectLayoutState = (state: any) => state.Ranking;

	const RankingProperties = createSelector(
		selectLayoutState,
		(state) => ({
			categories: state.categories,
			isCategorySuccess: state.isCategorySuccess,
			isCategoryLoading: state.isCategoryLoading,
			error: state.error,
		})
	);
	const { categories, isCategoryLoading } = useSelector(RankingProperties);

	useEffect(() => {
		dispatch(onGetCategories(query));
	}, [JSON.stringify(query)]);

	return (
		<Row>
			<Col xl={12}>
				<Row className="d-flex flex-md-nowrap overflow-x-auto" {...events} ref={ref}>
					{(categories || [null, null, null])?.map((item: any) => (
						<Col sm={12} md={categories?.length > 3 ? 3 : 4} className="col-ranking-category">
							<RecentTransaction data={item || null} />
						</Col>))}
				</Row>
			</Col>
		</Row>
	)
}

const RankingBoard = () => {
	const { t, i18n } = useTranslation();
	// const { changeBackground } = useContext(ThemeContext);
	// useEffect(() => {
	// 	changeBackground({ value: "light", label: "Light" });
	// }, []);

	useEffect(() => {
		document.title = t('Rankify Dashboard');
		// document.body.classList.remove('vertical-sidebar-enable');
	}, [i18n?.language]);

	return (
		<>
			<MainComponent />
		</>
	)
}

export default RankingBoard;
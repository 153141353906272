export const formatNumber = (num:number) => {
    return new Intl.NumberFormat('en-IN').format(num);
}

export const formatNumberWithCommas = (number: number | string = 0)  => {
    if(String(number) === 'NaN'){
        return 0;
    }
    return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const formatNumberWithoutCommas = (number: number | string = 0) => {
    return parseFloat(String(number).replace(/,/g, ''));
};

export const formatDateStringTo2Row = (date:string = '') => {
    const arr = String(date).split(' ');
    return `${arr[0]} <br/> ${arr[1]}`;
}


export const formatQueryParams = (query:any = {}) => {
    const clone = structuredClone(query);
    delete clone.time_request;
    delete clone.time_request_out;
    delete clone.time_request_inner;
    delete clone.category_level_1;
    delete clone.category_level_2;
    delete clone.interval_time;
    delete clone.is_clear;
    return clone;
}

export const formatLinkHTTPS = (linkString:string = '') => {
    if((String(linkString).includes('https://') || String(linkString).includes('http://'))){
        return linkString;
    } 
    return `https://${linkString || ''}`;
}
export function isAfter4PMKoreanTime() {
    const currentTime = new Date();
    const currentHour = currentTime.getUTCHours(); // Get the current hour in UTC
  
    // Convert to Korean time zone (UTC+9)
    const koreanTimeHour = currentHour + 9; // UTC+9
  
    // Check if it's after 16:00 in Korean time
    if (koreanTimeHour >= 16) {
      return true;
    } else {
      return false;
    }
}

export const renderUrlSearchLookingUpByIP = (ip:string = '') => {
    return `https://whatismyipaddress.com/ip/${ip}`;
}

export const replaceHalfWithStar = (inputString:string) => {
    var length = inputString.length;
    var halfLength = Math.floor(length / 2);
    var starsCount = length - halfLength;
    var replacedString = inputString.substring(0, halfLength) + '*'.repeat(starsCount);
    return replacedString;
}

export const formatHour = (hour:number) => {
    if (hour < 0 || hour > 23) {
        return 'Invalid hour';
    }
    
    let startHour = hour.toString().padStart(2, '0') + ":00";
   
    let endHour:any = hour + 1;
    if (endHour === 24) {
        endHour = "24:00";
    } else {
        endHour = endHour.toString().padStart(2, '0') + ":00";
    }

    return `${startHour} ~ ${endHour}`;
}

export const renderScriptURLOfInventory = (script_url:string = '', is_wrapper:boolean = false) => {
    if(is_wrapper){
        return `<div id="ahtm-banner" style="width: 100%"><script src="${script_url}"></script></div>`;
    }
    return `<script src="${script_url}"></script>`;
}
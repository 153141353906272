import authApi from "api/authApi";
import { useFormik } from "formik";
import { useEffect, useState } from 'react';
import { Spinner } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { loginSuccess } from "store/auth/login/reducer";
import useToastNotifications from "ui/components/Hooks/useToastNotifications";
import * as Yup from "yup";
import { IMAGES } from '../../constant/theme';

const SignUp: React.FC = (props) => {
	const { t, i18n } = useTranslation();
	const [isShow, setIsShow] = useState(false);
	const dispatch = useDispatch<any>();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { notify } = useToastNotifications();
	const handleSubmit = async (values: any) => {
		try {
			if (isLoading) {
				return;
			}
			setIsLoading((_prev) => true);
			const data = {
				name: values?.email,
				email: values?.email,
				password: values?.password,
			};
			const response: any = await authApi.signUp({
				...data,
				member_type: "regular"
			});
			setIsLoading((_prev) => false);
			if (response?.code === 200) {
				navigate('/verify-email', { state: data });
				// notify('success', t('SignUp success.'));
				// let finalLogin: any = response?.data;
				// localStorage.setItem("authUser", JSON.stringify(finalLogin));
				// dispatch(loginSuccess(finalLogin));
				// const referralUrl = localStorage.getItem('referralUrl') || '/';
				// localStorage.removeItem('referralUrl'); // Clean up after use
				// navigate(referralUrl);
			}
		} catch (errors: any) {
			setIsLoading((_prev) => false);
			const mess = typeof errors === 'string' ? t(`${errors}`) : Object.entries(errors)?.map(([key, value]) => {
				return t(`${(value as any || []).join('')}`);
			}).join('') || '';
			notify('error', mess);
			return errors;
		}
	};

	const validationSchema = Yup.object({
		email: Yup.string().email(`${t('Please enter a valid email address')}`).required(`${t('Please enter your email address.')}`),
		password: Yup.string()
			.min(8, t('At least 8 characters'))
			.matches(/[A-Z]/, t('Include an uppercase English letter'))
			.matches(/[0-9]/, t('Include a number'))
			.required(t('Please enter a password.'))
	});

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema,
		onSubmit: handleSubmit,
	});


	useEffect(() => {
		return () => {
			setIsLoading((_prev) => false);
		};
	}, []);

	return (
		<>
			<div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
				<div className="login-aside text-center  d-flex flex-column flex-row-auto justify-content-center">
					<div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
						<div className="text-center mb-lg-4 mb-2 pt-4 pt-md-0 logo">
							<Link to="/" className="d-block d-md-none" >
								<img src={IMAGES.LogoRankifyDark} alt="logo" style={{ width: 'auto' }} />
							</Link>
							<Link to="/" className="d-none d-md-block">
								<img src={IMAGES.LogoRankifyLight} alt="logo" style={{ width: 'auto' }} />
							</Link>
						</div>
						<h3 className="mb-2 text-white d-none d-md-block">{t('Welcome back!')}</h3>
						<p className="mb-4 d-none d-md-block">{t('Experience various indicators in different fields now')}</p>
					</div>
				</div>
				<div className="container flex-row-fluid d-flex flex-column justify-content-start justify-content-md-center position-relative overflow-hidden p-7 mx-auto">
					<div className="d-flex justify-content-center h-100 align-items-center">
						<div className="authincation-content style-2">
							<div className="row no-gutters">
								<div className="col-xl-12 tab-content">
									<div id="sign-up" className="auth-form tab-pane fade show active  form-validation">
										<form onSubmit={formik.handleSubmit}>
											<div className="text-center mb-4">
												<h3 className="text-center mb-2 text-dark">{t('Join the Membership')}</h3>
												{/* <span>Your Social Campaigns</span> */}
											</div>
											{/* <div className="sepertor">
												<span className="d-block mb-4 fs-13"></span>
											</div> */}
											<div className="mb-3">
												<label htmlFor="exampleFormControlInput1" className="form-label required">{t('Email address')}</label>
												<input type="text" className="form-control"
													name="email"
													value={formik?.values?.email}
													onChange={(e) => formik.setFieldValue("email", e?.target?.value || "")}
													placeholder={t('Please enter your email address.')}
													autoComplete="off"
												/>
												{(formik.touched.email && formik.errors.email) && (<div className="d-flex justify-content-start align-items-center mt-2">
													<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
														<path d="M12 4L4 12" stroke="#FD5353" stroke-width="1.5" stroke-linecap="round" />
														<path d="M12 12L4 4" stroke="#FD5353" stroke-width="1.5" stroke-linecap="round" />
													</svg>
													<div className="ms-1">
														{formik.errors.email || ''}
													</div>
												</div>)}
											</div>
											<div className="mb-2 position-relative">
												<label className="form-label required">{t('Password')}</label>
												<input
													type={isShow ? 'text' : 'password'}
													className="form-control"
													name="password"
													value={formik?.values?.password}
													placeholder={t('Please enter a password.')}
													autoComplete="off"
													onChange={(e) =>
														formik.setFieldValue("password", e?.target?.value || "")
													}
												/>
												<span className={`show-pass eye ${isShow ? 'active' : ''} `} onClick={() => setIsShow((prev) => !prev)}>
													<i className="fa fa-eye" />
													<i className="fa fa-eye-slash" />
												</span>
											</div>
											<div>
												<div className="d-flex justify-content-start align-items-center">
													{(![t('At least 8 characters'), t('Please enter a password.')]?.includes(formik.errors.password || '')) ?
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
															<path d="M3 8L6.75 12L13 4" stroke="#3C8AFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
														</svg> :
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
															<path d="M12 4L4 12" stroke="#FD5353" stroke-width="1.5" stroke-linecap="round" />
															<path d="M12 12L4 4" stroke="#FD5353" stroke-width="1.5" stroke-linecap="round" />
														</svg>
													}
													<div className="ms-1">
														{t('At least 8 characters')}
													</div>
												</div>
												<div className="d-flex justify-content-start align-items-center">
													{(![t('Include an uppercase English letter'), t('Please enter a password.')]?.includes(formik.errors.password || '')) ?
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
															<path d="M3 8L6.75 12L13 4" stroke="#3C8AFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
														</svg> :
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
															<path d="M12 4L4 12" stroke="#FD5353" stroke-width="1.5" stroke-linecap="round" />
															<path d="M12 12L4 4" stroke="#FD5353" stroke-width="1.5" stroke-linecap="round" />
														</svg>
													}
													<div className="ms-1">
														{t('Include an uppercase English letter')}
													</div>
												</div>
												<div className="d-flex justify-content-start align-items-center">
													{(![t('Include a number'), t('Please enter a password.')]?.includes(formik.errors.password || '')) ?
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
															<path d="M3 8L6.75 12L13 4" stroke="#3C8AFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
														</svg> :
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
															<path d="M12 4L4 12" stroke="#FD5353" stroke-width="1.5" stroke-linecap="round" />
															<path d="M12 12L4 4" stroke="#FD5353" stroke-width="1.5" stroke-linecap="round" />
														</svg>
													}
													<div className="ms-1">
														{t('Include a number')}
													</div>
												</div>
											</div>
											<div className="form-row d-flex justify-content-between mt-3">
												<div className="mb-1">
													<div className="form-check custom-checkbox mb-0">
														<input type="checkbox" className="form-check-input" checked={true} id="customCheckBox1" />
														<label className="form-check-label" htmlFor="customCheckBox1">
															{i18n?.language === 'en' ? <>
																<span className="text-decoration-underline">{t('I agree to the')}</span>
																{t('Terms of Service.')}
															</>
																:
																<>
																	<span className="text-decoration-underline">{t('Terms of Service.')}</span>
																	{t('I agree to the')}
																</>
															}
														</label>
													</div>
												</div>
											</div>
											<div className="form-row d-flex justify-content-between mb-2">
												<div className="mb-3">
													<div className="form-check custom-checkbox mb-0">
														<input type="checkbox" className="form-check-input" checked={true} id="customCheckBox2" />
														<label className="form-check-label" htmlFor="customCheckBox2">
															{i18n?.language === 'en' ? <>
																<span className="text-decoration-underline">{t('I agree to the')}</span>
																{t('privacy policy.')}
															</>
																:
																<>
																	<span className="text-decoration-underline">{t('privacy policy.')}</span>
																	{t('I agree to the')}
																</>
															}
														</label>
													</div>
												</div>
											</div>
											<button className="btn btn-block btn-primary" type="submit" disabled={isLoading}>
												<div className="fs-14" style={{ lineHeight: '28px', fontWeight: '500' }}>
													{isLoading ? <Spinner size="sm"></Spinner> : t('Join the Membership')}
												</div>
											</button>
										</form>
										<div className="new-account mt-3 text-center">
											<p className="font-w500">{t('Already have an account?')} <Link className="text-primary" to="/login" >{t('Go to log in')}</Link></p>
										</div>
									</div>
									{/* <div className="d-flex align-items-center justify-content-center">
										<Link to={"#"} className="text-primary">{t('Terms')}</Link>
										<Link to={"#"} className="text-primary mx-5">{t('Plans')}</Link>
										<Link to={"#"} className="text-primary">{t('Contact Us')}</Link>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</>
	);
};

export default SignUp;

import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { Dropdown } from "react-bootstrap";
import flagkorea from "../../../assets/images/flags/kr.svg";
import flagus from "../../../assets/images/flags/us.svg";

const languages: any = {
    en: {
        label: "English",
        flag: flagus,
    },
    ko: {
        label: "한국",
        flag: flagkorea,
    },
}
//i18n



const LanguageDropdown = () => {
    // Declare a new state variable, which we'll call "menu"
    const [selectedLang, setSelectedLang] = useState("ko");

    useEffect(() => {
        const currentLanguage: any = localStorage.getItem("I18N_LANGUAGE");
        setSelectedLang(currentLanguage);
    }, []);

    const changeLanguageAction = (e: any, lang: any) => {
        e.preventDefault();
        //set language as i18n
        i18n.changeLanguage(lang);
        localStorage.setItem("I18N_LANGUAGE", lang);
        setSelectedLang(lang);
    };

    return (
        <React.Fragment>
            <Dropdown as="li" className="nav-item notification_dropdown">
                <Dropdown.Toggle href={"" as any} className="nav-link i-false me-0" as="div">
                    <svg id="icon-database-widget" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <defs>
                            <clipPath id="clip-circle">
                                <circle cx="16" cy="16" r="12.5" />
                            </clipPath>
                        </defs>
                        <image
                            href={languages?.[selectedLang]?.flag}
                            width="25"
                            height="25"
                            x="3.5"
                            y="3.5"
                            clip-path="url(#clip-circle)"
                        />
                    </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu align="end" className="mt-3 pt-3 dropdown-menu dropdown-menu-right">
                    {Object.entries(languages).map(([key, value]: any) => (
                        <Dropdown.Item className="dropdown-item ai-icon" active={selectedLang === key} key={key} onClick={(e) => changeLanguageAction(e, key)}>
                            <img
                                src={value?.flag}
                                alt={value?.label}
                                className="me-2 rounded"
                                height="18"
                            />
                            <span className="ms-2 fs-13">{value?.label}</span>
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    );
};

export default LanguageDropdown;